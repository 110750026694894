import React, { Component } from 'react';
import { ImageBackground, Image, Dimensions, Linking, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Avatar, Button, Divider, Icon, ListItem, Overlay} from 'react-native-elements';
import { withRouter } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import MediaQuery from 'react-responsive';

import { parseQueryStringParams } from '../utilities/utilities';

import ActionBar from '../components/ActionBar';
import ProgressBar from '../components/ProgressBar';
import RankBadge from '../components/RankBadge';
import ButtonGroup from '../components/ButtonGroup';
import Carousel from '../components/Carousel';

import CheckoutModal from '../components/CheckoutModal';
import RecentDonationsModalMobile from '../components/RecentDonationsModalMobile';
import TopFundraisersModalMobile from '../components/TopFundraisersModalMobile';

import { getCampaign } from '../services/CampaignService';
import { getUser } from '../services/UserService';
import { fetchMemberships, getMembership } from '../services/MembershipService';
import { fetchSales } from '../services/SaleService';

import { getContrast } from '../utilities/utilities';

import AvatarDefault from "../assets/avatar-default.jpg";

import { charge } from '../services/SaleService';
import { TouchableWithoutFeedback } from 'react-native-web';

const moment = require('moment');

const { height, width } = Dimensions.get('window');

function selectAvatarSource(user, membership) {
  let src = AvatarDefault;

  if (membership?.userImage || user?.image) {
    src = {
      uri: membership?.userImage ? membership?.userImage : user?.image
    }
  }

  return src;
}

function formatNumber(num) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
 });

 return formatter.format(num);
}

function compareTotals(a, b) {
  if (a.totalSales > b.totalSales) {
    return -1;
  }

  if (a.totalSales < b.totalSales) {
    return 1
  }

  return 0;
}

function formatMessage(msg) {
  const words = msg.split(' ');

  let truncated = words.reduce((total, word) => {
    if (total.length + word.length <= 60) {
      total += word + ' ';
    }

    return total;
  }, '').trim();

  if (msg.length > truncated.length) {
    truncated += '...';
  }

  return truncated;
}

class MemberScreenMobile extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      quantity: 1,
      user: null,
      campaign: null,
      isVisible: false,
      donationAmount: 0,
      sales: [],
      memberships: []
    }

    this._loadData = this._loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.share = this.share.bind(this);
  }

  async componentDidMount() {
    await this._loadData();
  }

  async _loadData() {
    const query = this.props.location.search;
    let params;

    if (query) {
      params = parseQueryStringParams(query);
      this.setState({
        queryParams: params
      });
      console.log("PARAMS", params);
    }

    try {

      // if (!params?.userId || !params?.campaignId) {
      //   throw new Error("Missing Parameters: Unable to load data");
      // }
      

      const userReq = getUser(this.props.match.params.memberId);
      // const campaignReq = getCampaign(params.campaignId);
      const campaignReq = getCampaign(this.props.match.params.campaignId);
      const membershipReq = getMembership(this.props.match.params.memberId, this.props.match.params.campaignId);
      const campaignMembershipsReq = fetchMemberships('campaignId', this.props.match.params.campaignId, {
        "filter[totalSales]": 0,
        "page[size]": 500,
        "sort[order]": "asc"
      })
      
      const saleOptions = {
        "campaign": this.props.match.params.campaignId
      }
    
      const salesReq = fetchSales(saleOptions);

      const [user, campaign, membership, memberships, sales] = await Promise.all([userReq, campaignReq, membershipReq, campaignMembershipsReq, salesReq]);
      // const [campaign] = await Promise.all([campaignReq]);
      console.log("SALES", sales);
      this.setState({
        user: user.data,
        campaign: campaign.data,
        membership: membership.data,
        memberships: memberships.data,
        sales: sales.data,
        queryParams: params || {}
        // platform: params.platform
      }, () => {
        document.title = `${this.state.membership?.userFirst} ${this.state.membership?.userLast} | ${this.state.campaign?.name}`;
      });
      // this.setState({
      //   campaign: campaign.data,
      // });

    } catch(error) {
      console.log("ERROR LOADING DATA IN LANDING", error);
    }
  }

  handleChange(evt, name) {
    this.setState({
      [name]: evt.nativeEvent.text
    });
  }

  async handleSubmit(values) {

    const { cardName, cardNumber, cvc, expiryMonth, expiryYear, memberships, isVisible, isLoading, willPayProcessingFee, ...payload } = values;

    // Format credit card data for backend consumption
    payload.card = {
      number: cardNumber,
      cvc,
      exp_month: Number(expiryMonth),
      exp_year: Number(expiryYear)
    };

    payload.campaign = this.props.match.params.campaignId;
    payload.team = this.state.membership?.team;
    payload.invite = this.state.queryParams?.invite;
    payload.platform = this.state.queryParams?.platform;
    payload.donationAmount = Number(payload.donationAmount);
    payload.processingFee = willPayProcessingFee ? Number((Number(payload.donationAmount) * 0.042).toFixed(2)) : 0;
    console.log("MOBILE")
    console.log("PAYMENT PAYLOAD", payload);

    try {
      // const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      
      // await timeout(1000);

      // throw "test error";
      const response = await charge(payload);
      console.log("RESPONSE FROM CHARGE", response);
      return response;
      // console.log("CHARGING");
    } catch(err) {
      console.log('ERROR CHARGING', err);
      throw new Error(err);
    }
  }

  toggleModal(initialData = {}) {
    return () => {
      this.setState({
        isVisible: !this.state.isVisible,
        submittedSuccessfully: false,
        ...initialData
      })
    }
  }

  share(platform, afterDonation) {
    return async () => {
      const { campaign, membership } = this.state;
      const { facebook, twitter } = membership?.shareableLinks;

      const verbTense = afterDonation ? 'supported' : 'support';

      const FACEBOOK_MESSAGE = `I ${verbTense} ${membership?.userFirst} ${membership?.userLast} and ${campaign?.name}.  Check out their page to join me in supporting them too!`;
      const TWITTER_MESSAGE = `I ${verbTense} ${membership?.userFirst} ${membership?.userLast} and ${campaign?.name}.  Check out their page to join me in supporting them too!`;

      try {
        let url = '';
        switch(platform) {
          case 'facebook':
            url = `https://www.facebook.com/sharer/sharer.php?quote=${FACEBOOK_MESSAGE}&u=${facebook}`;
            break;
          case 'twitter':
            url = `https://twitter.com/compose/tweet?text=${`${TWITTER_MESSAGE} ${twitter}`}`;
            break;
        }
        await Linking.openURL(url);
      } catch(err) {
        console.log("ERROR LOADING IN BROWSER", err);
      }
    }
  }

  render() {
    const { campaign, membership, user, memberships } = this.state;

    console.log("Campaign-------------------", campaign);
    console.log("MEMBERSHIPS", memberships);

    const modalComponent = () => {
      if (this.state.modalType === 'recentDonations') {
        return RecentDonationsModalMobile;
      } else if (this.state.modalType === 'topFundraisers') {
        return TopFundraisersModalMobile;
      } else {
        return CheckoutModal;
      }
    }

    return (
      <View style={{}}>
        <Overlay
          ModalComponent={modalComponent()}
          data={{
            donationAmount: this.state.donationAmount,
            memberships: this.state.memberships || [],
            user: this.state.membership?.user,
            membership: this.state.membership,
            user: this.state.user,
            isVisible: this.state.isVisible,
            sales: this.state.sales
          }}
          theme={campaign?.theme}
          handleClose={this.toggleModal}
          handleSubmit={this.handleSubmit}
          handleShare={this.share}
        ></Overlay>
        <ScrollView
          style={[styles.container]}
          showsVerticalScrollIndicator={false}
        >
          <View style={{backgroundColor: campaign?.theme.primary, height: height * 0.01}}></View>
          <View style={{height: height * 0.5, zIndex: 2}}>
            {/* <ImageBackground
              source={{uri: campaign?.media?.images ? campaign?.media?.images[0] : 'https://media1.s-nbcnews.com/i/newscms/2020_30/3398393/200720-mater-dei-at-st-john-bosco-ew-351p_ae9fa84a450bee5c4f37e61861ec3ff3.jpg'}}
              style={{
                flex: 1,
                backgroundColor: '#000000'
              }}
              imageStyle={{opacity: 0.5}}
            > */}
            <Carousel
              images={campaign?.media?.images}
              style={{
                flex: 1,
                backgroundColor: '#000000'
              }}
              imageStyle={{opacity: 0.5}}
            >
              <>
                <View style={{height: '18%', backgroundColor: 'transparent', paddingHorizontal: width * 0.05, flexDirection: 'row'}}>
                  <View style={{height: '100%', width: '20%'}}>
                    <Image
                      source={{ uri: campaign?.organization_logo }}
                      style={{
                        width: undefined,
                        height: '100%',
                        backgroundColor: 'transparent'
                      }}
                      resizeMode="contain"
                    />
                  </View>
                  <View style={{flex: 1, justifyContent: 'center', paddingLeft: '2%'}}>
                      <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 14, lineHeight: 18, letterSpacing: 1, color: '#FFFFFF', textTransform: 'uppercase'}}>{campaign?.name}</Text>
                  </View>
                </View>
                <View style={{flex: 1, justifyContent: 'center'}}>
                  <View style={{paddingHorizontal: 20}}>
                    <View style={{flexDirection: 'row', marginBottom: 15}}>
                      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        {
                          // membership?.userImage &&
                          <Avatar
                            rounded
                            // source={{ uri: membership?.userImage || user?.image }}
                            source={selectAvatarSource(user, membership)}
                            size="large"
                            containerStyle={{
                              borderWidth: 2,
                              borderColor: campaign?.theme.secondary
                            }}
                          />
                        }
                      </View>
                      <View style={{flex: 3, justifyContent: 'center'}}>
                        <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 24, lineHeight: 31, letterSpacing: 3, color: '#FFFFFF'}}>{`${membership?.userFirst} ${membership?.userLast}`}</Text>
                        <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 16, lineHeight: 31, letterSpacing: 1, color: getContrast(campaign?.theme.secondary) === 'black' ? campaign?.theme.secondary : '#FFFFFF'}}>HELP ME REACH MY GOAL</Text>
                      </View>
                    </View>
                    <ProgressBar
                      barContainerStyle={{height: 40}}
                      containerColor={campaign?.theme.secondary}
                      barColor={campaign?.theme.primary}
                      fillText={`${membership?.userFirst} RAISED $${formatNumber(membership?.totalSales)}`.toUpperCase()}
                      barText={`ONLY $${membership?.goal - membership?.totalSales > 0 ? formatNumber(membership?.goal - membership?.totalSales) : 0} TO GO`}
                      textContainerStyle={{paddingHorizontal: 10}}
                      fillTextStyle={{
                        fontFamily: 'helvetica-neue-bold',
                        fontSize: 12,
                        lineHeight: 15,
                        letterSpacing: 0.58,
                        color: getContrast(campaign?.theme.primary) === 'black' ? '#000000' : '#FFFFFF'
                      }}
                      barTextStyle={{
                        fontFamily: 'helvetica-neue-bold',
                        fontSize: 13,
                        lineHeight: 16,
                        letterSpacing: 0.62,
                        color: getContrast(campaign?.theme.secondary) === 'black' ? '#000000' : '#FFFFFF'
                      }}
                      data={{
                        value: membership?.totalSales,
                        total: campaign?.userGoal
                      }}
                    />
                  </View>
                </View>
              </>
            </Carousel>
            {/* </ImageBackground> */}
          </View>
          <View style={{paddingTop: '5%', paddingHorizontal: 20}}>
            <View style={{flex: 1, marginBottom: 20}}>
              <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 24, lineHeight: 36, letterSpacing: 1.15, color: '#333333'}}>
                THANK YOU 
                <Text style={{fontFamily: 'helvetica-regular', fontSize: 24, lineHeight: 36, letterSpacing: 1.15, color: '#333333'}}> FOR YOUR SUPPORT</Text>
              </Text>
              <View style={{marginBottom: 40, marginTop: 30, width: '100%'}}>
                {
                  campaign?.media?.videos?.campaign?.videoUrl &&
                  <Vimeo
                    video={campaign?.media?.videos?.campaign?.videoUrl}
                    responsive={true}
                    style={{}}
                  />
                }
              </View>
              <View style={{height: height * 0.35, borderRadius: 4, width: '100%', boxShadow: '0 0 3px 8px rgba(88,88,88,0.05)', marginBottom: 30}}>
                <View style={{flex: 1, backgroundColor: campaign?.theme.secondary, justifyContent: 'space-around', paddingHorizontal: '10%', paddingVertical: '3%'}}>
                  <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 22, lineHeight: 27, color: getContrast(campaign?.theme.secondary) === 'black' ? '#000000' : '#FFFFFF'}}>SHARE FUNDRAISER</Text>
                  <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 22, color: getContrast(campaign?.theme.secondary) === 'black' ? '#000000' : '#FFFFFF'}}>
                    Share this on social media and really make a difference
                  </Text>
                </View>
                <View style={{height : '10%', backgroundColor: campaign?.theme.secondary, borderRadius: '50%', borderTopLeftRadius: 0, borderTopRightRadius: 0}}>

                </View>
                <View style={{flex: 1, backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center'}}>
                  <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                    <Icon
                      name='facebook-f'
                      type='font-awesome'
                      reverse
                      iconStyle={{fontSize: 50}}
                      underlayColor="blue"
                      color="#4468A3"
                      size={35}
                      containerStyle={{marginRight: 15}}
                      onPress={this.share('facebook')}
                    />
                    <Icon
                      reverse
                      name='twitter'
                      type='font-awesome'
                      color="#1DA1F2"
                      size={35}
                      iconStyle={{fontSize: 50}}
                      containerStyle={{marginLeft: 15}}
                      onPress={this.share('twitter')}
                    />
                  </View>
                </View>
              </View>
              <View style={{paddingTop: '4%', marginBottom: 30}}>
                <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#333333'}}>
                {
                campaign?.meta?.campaignMessage ||
                `Thank you for visiting our fundraising page.  We are very excited to start the season up again and look forward to the challenges ahead.

We have many goals set for ourselves and have been working tirelessly to achieve them this past year.  Some of our goals are individual ones everyone has been working on independently, others are team goals we work on all together, and lastly we have financial goals we need to meet in order to accomplish them all.  Running a successful program is expensive and we need your help.
                
Please donate as much as you can to help as it is much needed and greatly appreciated. With your help we can really accomplish everything we have set out to do this year.
                
Please remember to share, tweet, post, text, or email our campaign to others.`}
                </Text>
              </View>
              <View style={{height: height * 0.6, backgroundColor: '#F6F6F6', width: '100%', borderLeftWidth: 6, borderLeftColor: campaign?.theme.primary, borderRadius: 4, paddingHorizontal: '5%', justifyContent: 'space-evenly', marginBottom: 20}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 22, lineHeight: 27, color: '#333333'}}>RECENT DONATIONS</Text>
                {
                  [...this.state.sales].slice(-3).reverse().map(sale => {
                    return (
                      <>
                        <View>
                          <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10}}>
                            <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>{sale?.identityDisplayType === 0 ? sale?.customer.name : 'Anonymous'}</Text>
                            <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>{`$${sale?.paymentAmount}`}</Text>
                          </View>
                          {
                            sale?.message &&
                            <View style={{}}>
                              <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 25, color: '#333333'}}>{formatMessage(sale?.message)}</Text>
                              {/* <Text style={{ fontFamily: 'chalkboard-regular', fontSize: 200, color: '#EEEEEE', color: '#000000'}}>"</Text> */}
                            </View>
                          }
                        </View>
                        <Divider />
                      </>
                    )
                  })
                }
                <TouchableWithoutFeedback onPress={this.toggleModal({modalType: "recentDonations"})}>
                  <View style={{alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#000000'}}>View More</Text>
                  </View>
                </TouchableWithoutFeedback>
              </View>
              <View style={{minHeight: height * 0.6, paddingVertical: 30, flexDirection: 'column', backgroundColor: '#F6F6F6', width: '100%', borderLeftWidth: 6, borderLeftColor: campaign?.theme.primary, borderRadius: 4, paddingHorizontal: '5%'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 22, lineHeight: 27, color: '#333333'}}>TOP FUNDRAISERS</Text>
                <View style={{justifyContent: 'space-between', flex: 1, paddingTop: 20}}>
                  {
                    this.state.memberships.sort(compareTotals).slice(0,3).map((membership, idx) => {
                      return (
                        <View style={{height: '25%'}}>
                          <View style={{justifyContent: 'center', paddingVertical: 20}}>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10, alignItems: 'center'}}>
                              <RankBadge
                                fillColor={campaign?.theme.primary}
                                strokeColor={campaign?.theme.primary}
                                labelColor={getContrast(campaign?.theme.primary) === 'black' ? '#000000' : '#FFFFFF'}
                                labelStyle={{ fontFamily: 'helvetica-neue-bold', fontSize: 25, lineHeight: 30, letterSpacing: 1.53 }}
                                rank={idx + 1}
                              />
                              <View style={{ flex: 1, alignContent: 'flex-start', paddingHorizontal: 20}}>
                                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>{`${membership.userFirst} ${membership.userLast}`}</Text>
                                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>{`$${membership.totalSales}`}</Text>
                              </View>
                            </View>
                          </View>
                          <Divider />
                        </View>
                      )
                    })
                  }
                  <TouchableWithoutFeedback onPress={this.toggleModal({modalType: "topFundraisers"})}>
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                      <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#000000'}}>View More</Text>
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              </View>
            </View>
          </View>
          <View style={{}}>
            <ImageBackground
              source={{uri: 'https://media1.s-nbcnews.com/i/newscms/2020_30/3398393/200720-mater-dei-at-st-john-bosco-ew-351p_ae9fa84a450bee5c4f37e61861ec3ff3.jpg'}}
              style={{
                flex: 1,
                backgroundColor: '#000000'
              }}
              imageStyle={{opacity: 0.5}}
              blurRadius={7}
            >
              <View style={{paddingVertical: 40, paddingHorizontal: 20}}>
                <View style={{paddingBottom: 20}}>
                  <View style={{paddingBottom: 15}}>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 26, lineHeight: 36, color: '#FFFFFF', fontWeight: 'bold'}}>WHY </Text>
                      <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 26, lineHeight: 36, color: '#FFFFFF'}}>YOUR </Text>
                      <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 26, lineHeight: 36, color: '#FFFFFF'}}>SUPPORT </Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      {/* <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 26, lineHeight: 36, color: '#FFFFFF'}}>SUPPORT </Text> */}
                      <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 26, lineHeight: 36, color: '#FFFFFF', fontWeight: 'bold'}}>MATTERS</Text>
                    </View>
                  </View>
                  <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 18, lineHeight: 27, color: '#FFFFFF', fontWeight: 'bold'}}>
                  {
                  campaign?.meta?.donationPitch ||
                  `We require a lot of our athletes both on and off the field. On top of daily sport specific training, we focus on school work, community stewardship, and personal development.

Cost associated with our efforts are substantially more than we receive. We rely on the support of friends, family, and the community in order to fulfill our goals and stay competitive.`}
                  </Text>
                </View>
                <View style={{backgroundColor: '#FFFFFF', borderLeftWidth: 6, borderLeftColor: campaign?.theme?.primary, borderRadius: 4, paddingVertical: 40, paddingHorizontal: 20}}>
                  <View style={{paddingBottom: 20}}>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 24, lineHeight: 32, color: '#333333', fontWeight: 'bold'}}>HOW </Text>
                      <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 24, lineHeight: 32, color: '#333333'}}>YOUR </Text>
                      <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 24, lineHeight: 32, color: '#333333'}}>DONATION </Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      {/* <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 24, lineHeight: 32, color: '#333333'}}>DONATION </Text> */}
                      <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 24, lineHeight: 32, color: '#333333', fontWeight: 'bold'}}>IS USED</Text>
                    </View>
                  </View>
                  <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#333333'}}>
                    {
                      campaign?.meta?.donationPurpose ||
                     "Your donation will be used to pay for uniforms, practice gear, travel expenses, tournament fees, and the team meals.  All of your donations will be used exclusively to further the success of our program."
                    }
                  </Text>
                </View>
              </View>
            </ImageBackground>
          </View>
          <View style={{background: 'repeating-linear-gradient(55deg, #E1E1E1, #E1E1E1 100px, #F0F0F0 100px, #F0F0F0 200px)', alignItems: 'center', paddingHorizontal: 30, marginBottom: height * 0.12}}>
              <View style={{height: '33%', paddingVertical: 40, alignItems: 'center', textAlign: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 18, lineHeight: 23, color: '#333333'}}>© Copyright 2021 American Youth Services</Text>
                <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 18, lineHeight: 22, color: '#333333', fontWeight: 'bold', marginTop: '3%'}}>6628 E Baseline Rd #102 · Mesa, AZ 85206 · 888-450-3634 · americanyouth.com</Text>
              </View>
          </View>
        </ScrollView>
        <ActionBar
          theme={{
            primary: campaign?.theme.primary,
            secondary: campaign?.theme.secondary
          }}
          containerStyle={{
            position: 'fixed',
            width: width,
            height: height * 0.15
          }}
          handlePress={this.toggleModal}
          data={{
            value: campaign?.numSales || 0,
            total: campaign?.goal || 1
          }}
          fillTextStyle={{ color: getContrast(campaign?.theme.primary) === 'black' ? '#000000' : '#FFFFFF' }}
          barTextStyle = {{ color: getContrast(campaign?.theme.secondary) === 'black' ? '#000000' : '#FFFFFF' }}
          fillText={`$${formatNumber(campaign?.numSales)} RAISED`}
          barText={`ONLY $${campaign?.goal - campaign?.numSales > 0 ? formatNumber(campaign?.goal - campaign?.numSales) : 0} TO GO`}
          barContainerStyle={{height: 30}}
          textContainerStyle={{paddingHorizontal: 10}}
        >
          {
            // campaign && moment().isAfter(moment(campaign.end).add(7, 'd')) &&
            campaign && (campaign.state === 'COMPLETE' || campaign.state === 'ARCHIVED') &&
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 25, lineHeight: 15, letterSpacing: 2.4, color: '#000000', textTransform: 'uppercase', paddingBottom: 15}}>Fundraiser Over</Text>
              <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 12, lineHeight: 7.5, letterSpacing: 1.2, color: campaign?.theme.primary || '#000000', textTransform: 'uppercase'}}>{`Completed on ${moment(campaign.end).format('MM/DD/YYYY')}`}</Text>
            </View>
          }
          {
            // campaign && !moment().isAfter(moment(campaign.end).add(7, 'd')) &&
            campaign && (campaign.state === 'PENDING' || campaign.state === 'ACTIVE') &&
            <ButtonGroup>
              <Button
                style={{flex: 1}}
                containerStyle={{flex: 1, borderRadius: 0}}
                onPress={this.toggleModal({donationAmount: 35, modalType: 'checkout'})}
                ViewComponent={() => (
                  <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 15, fontSize: 12, letterSpacing: 0.58, color: '#000000'}}>Donate</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: campaign?.theme.primary || '#086B3A'}}>$35</Text>
                  </View>
                  )
                }
              />
              <Button
                style={{flex: 1}}
                containerStyle={{flex: 1, borderRadius: 0}}
                onPress={this.toggleModal({donationAmount: 50, modalType: 'checkout'})}
                ViewComponent={() => (
                  <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 15, fontSize: 12, letterSpacing: 0.58, color: '#000000'}}>Donate</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: campaign?.theme.primary || '#086B3A'}}>$50</Text>
                  </View>
                  )
                }
              />
              <Button
                style={{flex: 1}}
                containerStyle={{flex: 1, borderRadius: 0}}
                onPress={this.toggleModal({donationAmount: 100, modalType: 'checkout'})}
                ViewComponent={() => (
                  <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 15, fontSize: 12, letterSpacing: 0.58, color: '#000000'}}>Donate</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: campaign?.theme.primary || '#086B3A'}}>$100</Text>
                  </View>
                  )
                }
              />
              <Button
                style={{flex: 1}}
                containerStyle={{flex: 1, borderRadius: 0}}
                onPress={this.toggleModal({donationAmount: 250, modalType: 'checkout'})}
                ViewComponent={() => (
                  <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 15, fontSize: 12, letterSpacing: 0.58, color: '#000000'}}>Donate</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: campaign?.theme.primary || '#086B3A'}}>$250</Text>
                  </View>
                  )
                }
              />
              <Button
                style={{flex: 1}}
                containerStyle={{flex: 1, borderRadius: 0}}
                onPress={this.toggleModal({donationAmount: "", modalType: 'checkout'})}
                ViewComponent={() => (
                  <View style={{backgroundColor: campaign?.theme.primary || '#086B3A', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 15, fontSize: 12, letterSpacing: 0.58, color: '#FFFFFF'}}>CUSTOM</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 15, fontSize: 12, letterSpacing: 0.58, color: '#FFFFFF'}}>AMOUNT</Text>
                  </View>
                  )
                }
              />
            </ButtonGroup>
          }
        </ActionBar>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height,
    width: '100%',
    backgroundColor: '#FFFFFF'
  },
  cardContainer: {
    display: 'inline-block',
    width,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: width * 0.05,
    paddingVertical: width * 0.1
  },
  image: {
    alignSelf: 'flex-end'
  },
  contentContainer: {
    paddingHorizontal: width * 0.05,
    paddingVertical: width * 0.1
  },
  titleContainer: {

  },
  title: {
    fontFamily: 'helvetica-neue-bold',
    fontSize: 16,
    letterSpacing: 0.23,
    lineHeight: 19,
    color: '#000000',
    fontWeight: '600'
  },
  description: {
    fontSize: 15,
    lineHeight: 22,
    color: '#666666',
    fontFamily: 'helvetica-regular'
  },
  btnContainer: {
    backgroundColor: "#00A9FF",
    width: '40%'
  },
  btnStyle: {
    backgroundColor: "#00A9FF",
    paddingVertical: 15
  },
  btnLabel: {
    fontSize: 15,
    letterSpacing: -0.36,
    lineHeight: 22,
    fontFamily: 'helvetica-regular'
  }
})

export default withRouter(MemberScreenMobile);
