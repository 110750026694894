import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { ListItem } from 'react-native-elements';
import { Field } from 'formik';

const { width } = Dimensions.get('window');

const OrderConfirmation = () => {
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>Review Order</Text>
      </View>
      <View style={styles.menuContainer}>
        <Field>
          {({ form }) => (
            <ListItem
              title="Customer"
              titleStyle={styles.menuItemTitle}
              containerStyle={styles.menuItemContainer}
              bottomDivider
              rightElement={
                <View style={{alignItems: 'flex-end', paddingVertical: 5}}>
                  <Text style={styles.text}>{`${form.values.customer.firstName} ${form.values.customer.lastName}`}</Text>
                </View>
              }
            />
          )}
        </Field>
        <Field>
          {({ form }) => (
            <ListItem
              title="Sold By"
              titleStyle={styles.menuItemTitle}
              containerStyle={styles.menuItemContainer}
              bottomDivider
              rightElement={
                <View style={{alignItems: 'flex-end', paddingVertical: 5}}>
                  <Text style={styles.text}>{`${form.values.userFirstName} ${form.values.userLastName}`}</Text>
                </View>
              }
            />
          )}
        </Field>
        <Field name="unitsSold">
          {({ field }) => (
            <ListItem
              title="Units Purchased"
              titleStyle={styles.menuItemTitle}
              containerStyle={styles.menuItemContainer}
              bottomDivider
              rightElement={
                <View style={{alignItems: 'flex-end', paddingVertical: 5}}>
                  <Text style={styles.text}>{field.value}</Text>
                </View>
              }
            />
          )}
        </Field>
        <Field name="paymentAmount">
          {({ field }) => (
            <ListItem
              title="Price"
              titleStyle={styles.menuItemTitle}
              containerStyle={styles.menuItemContainer}
              bottomDivider
              rightElement={
                <View style={{alignItems: 'flex-end', paddingVertical: 5}}>
                  <Text style={styles.text}>{`$${field.value}`}</Text>
                </View>
              }
            />
          )}
        </Field>
        <Field>
          {() => (
            <ListItem
              title="Payment Method"
              titleStyle={styles.menuItemTitle}
              containerStyle={styles.menuItemContainer}
              bottomDivider
              rightElement={
                <View style={{alignItems: 'flex-end', paddingVertical: 5}}>
                  <Text style={styles.text}>Credit</Text>
                </View>
              }
            />
          )}
        </Field>
        <Field name="cardNumber">
          {({ field }) => (
            <ListItem
              title="Card Ending In"
              titleStyle={styles.menuItemTitle}
              containerStyle={styles.menuItemContainer}
              bottomDivider
              rightElement={
                <View style={{alignItems: 'flex-end', paddingVertical: 5}}>
                  <Text style={[styles.text]}>{field.value.slice(-4)}</Text>
                </View>
              }
            />
          )}
        </Field>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingTop: 15
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 5
  },
  headerText: {
    color: '#000000',
    fontSize: 17,
    lineHeight: 22,
    letterSpacing: -0.41,
    fontFamily: 'helvetica-neue-bold'
  },
  menuContainer: {
    // flex: 2,
    // paddingTop: 10,
    // paddingLeft: WIDTH * 0.05
  },
  menuItemContainer: {
    paddingVertical: 10,
    paddingRight: width * 0.05,
    paddingLeft: 0,
    margin: StyleSheet.hairlineWidth
  },
  menuItemTitle: {
    color: "#333333",
    fontSize: 16,
    letterSpacing: -0.38,
    lineHeight: 22,
    fontFamily: 'helvetica-neue-medium'
  },
  text: {
    color: "#8E8E93",
    fontSize: 16,
    letterSpacing: -0.38,
    lineHeight: 22,
    paddingVertical: 1
  }
})

export default OrderConfirmation;