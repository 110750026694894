import React, { Component } from 'react';
import { Dimensions, ScrollView, Text, View } from 'react-native';
import { Avatar, Button, CheckBox, Divider, Icon } from 'react-native-elements';
import MediaQuery from 'react-responsive';

import Modal from 'modal-react-native-web';

const { width, height } = Dimensions.get('window');

class RecentDonationsModalMobile extends Component {

  render() {
    const { data, theme } = this.props;
    console.log("RENDERING RECENT DONATIONS", data.sales);
    return (
      <View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.props.data.isVisible === undefined ? false : this.props.data.isVisible}
          onDismiss={() => {
            console.log("DISMISSING MODAL");
          }}
        >
          <View style={{height, backgroundColor: '#F6F6F6'}}>
            <View style={{height: '12%', flexDirection: 'row', alignItems: 'center', paddingHorizontal: '5%', justifyContent: 'space-between'}}>
              <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 22, lineHeight: 27, color: '#333333'}}>RECENT DONATIONS</Text>
              <Icon
                type="antdesign"
                name="close"
                onPress={this.props.handleClose()}
              />
            </View>
            <Divider/>
            <ScrollView contentContainerStyle={{flexGrow: 1}}>
              <View style={{
                height: '100%',
                backgroundColor: '#F6F6F6',
                width: '100%',
                borderLeftWidth: 6,
                borderLeftColor: theme.primary,
                paddingHorizontal: '5%',
                marginBottom: 20}}>
                {
                  [...data.sales].reverse().map(sale => {
                    return (
                      <>
                        <View style={{paddingVertical: 15}}>
                          <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10}}>
                            <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>{sale?.identityDisplayType === 0 ? sale?.customer.name : 'Anonymous'}</Text>
                            <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>{`$${sale?.paymentAmount}`}</Text>
                          </View>
                          {
                            sale?.message &&
                            <View style={{}}>
                              <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 25, color: '#333333'}}>{sale?.message}</Text>
                              {/* <Text style={{ fontFamily: 'chalkboard-regular', fontSize: 200, color: '#EEEEEE', color: '#000000'}}>"</Text> */}
                            </View>
                          }
                        </View>
                        <Divider />
                      </>
                    )
                  })
                }
              </View>
            </ScrollView>
          </View>
        </Modal>
      </View>
    );
  }
}

export default RecentDonationsModalMobile;