import React, { Component } from 'react';
import { Dimensions, ScrollView, Text, View } from 'react-native';
import { Avatar, Button, CheckBox, Divider, Icon } from 'react-native-elements';
import { FastField, Field, Formik } from 'formik';
import MediaQuery from 'react-responsive';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import '../toast.css';
import 'react-toastify/dist/ReactToastify.css';


import Modal from 'modal-react-native-web';

import Dropdown from '../components/Dropdown';
import Input from '../components/Input';

import { getContrast } from '../utilities/utilities';

import { IDENTITY_DISPLAY_OPTIONS, MONTHS, STATES } from '../constants';

const moment = require('moment');
const _ = require('lodash');

const { width, height } = Dimensions.get('window');

let validationSchema = Yup.object().shape({
  customer: Yup.object().shape({
    name: Yup.string()
      .required(),
    email: Yup.string()
      .email()
      .required(),
    address: Yup.string()
      .required(),
    city: Yup.string()
      .required(),
    state: Yup.string()
      .required(),
    zipCode: Yup.string()
      .required(),
    phone: Yup.string()
      .required(),
  }),
  cardName: Yup.string()
    .required(),
  cardNumber: Yup.string()
    .required(),
  cvc: Yup.string()
    .required(),
  expiryMonth: Yup.string()
    .required(),
  expiryYear: Yup.string()
    .required(),
  donationAmount: Yup.string()
    .required(),
});

function generateYearList(date, numYears) {
  const currentYear = date ? moment(date) : moment()

  const years = _.times(numYears, (idx) => {

    const year = currentYear.clone().add(idx, 'y').year();
    return {
      label: year.toString(),
      value: year
    }

  });

  return years;
}

class CheckoutModal extends Component {
  state = {
    submittedSuccessfully: this.props.submittedSuccessfully
  }

  shouldComponentUpdate(nextProps) {
    console.log("NEXT PROPS", nextProps);
    return true;
  }

  render() {
    const { data, handleSubmit, theme } = this.props;
    console.log("Is VISIBLE INSIDE", this.props.data);

    const defaults = {
      willPayProcessingFee: false,
      message: '',
      customer: {
        name: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        phone: ''
      },
      identityDisplayType: 0,
      donationAmount: this.props.data?.donationAmount || "",
      cardName: '',
      cardNumber: '',
      cvc: '',
      expiryMonth: '',
      expiryYear: '',
      user: '',
    }

    const YEARS = generateYearList(null, 7);

    return (
      <View>
        <MediaQuery maxDeviceWidth={480}>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.props.data.isVisible === undefined ? false : this.props.data.isVisible}
            onDismiss={() => {
              console.log("DISMISSING MODAL");
            }}
          >
            <View style={{flex: 1, height: '100%', backgroundColor: 'rgba(0,0,0,0.75)'}}>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              {
                this.state.submittedSuccessfully &&
                <>
                  <MediaQuery maxDeviceWidth={480}>
                    <View style={{flex: 1, backgroundColor: '#FFFFFF', alignItems: 'center', paddingHorizontal: width * 0.05}}>
                      <View style={{height: height * 0.05, width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Icon
                          type="antdesign"
                          name="close"
                          onPress={this.props.handleClose()}
                        />
                      </View>
                      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        <Avatar
                          rounded
                          source={{ uri: this.props.data.membership?.userImage || this.props.data.user?.image }}
                          size={125}
                          containerStyle={{
                            // borderWidth: 2,
                            // borderColor: campaign?.theme.secondary
                          }}
                        />
                        <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 25, lineHeight: 30, letterSpacing: 1.2, color: '#333333', paddingVertical: 20}}>THANK YOU!</Text>
                        <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 18, lineHeight: 27, letterSpacing: 1.2, color: '#333333'}}>Help us further by sharing on</Text>
                        <View style={{flexDirection: 'row', justifyContent: 'center', paddingVertical: 20}}>
                          <Icon
                            name='facebook-f'
                            type='font-awesome'
                            reverse
                            iconStyle={{fontSize: 40}}
                            underlayColor="blue"
                            color="#4468A3"
                            size={30}
                            containerStyle={{marginRight: 15}}
                            onPress={this.props.handleShare('facebook', true)}
                          />
                          <Icon
                            reverse
                            name='twitter'
                            type='font-awesome'
                            color='#f50'
                            color="#1DA1F2"
                            size={30}
                            iconStyle={{fontSize: 40}}
                            containerStyle={{marginLeft: 15}}
                            onPress={this.props.handleShare('twitter', true)}
                          />
                        </View>
                        <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#333333', textAlign: 'center'}}>
                          Your donation was received and you will receive an email receipt shortly. If you want to help us more you can share this fundraiser with others on social media!
                        </Text>
                      </View>
                    </View>
                  </MediaQuery>
                  <MediaQuery minDeviceWidth={481}>
                    <View style={{flex: 1, backgroundColor: '#FFFFFF', alignItems: 'center', paddingHorizontal: width * 0.05}}>
                      <View style={{height: height * 0.05, width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Icon
                          type="antdesign"
                          name="close"
                          onPress={this.props.handleClose()}
                        />
                      </View>
                      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        <Avatar
                          rounded
                          source={{ uri: this.props.data.membership?.userImage || this.props.data.user?.image }}
                          size={125}
                          containerStyle={{
                            // borderWidth: 2,
                            // borderColor: campaign?.theme.secondary
                          }}
                        />
                        <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 25, lineHeight: 30, letterSpacing: 1.2, color: '#333333', paddingVertical: 20}}>THANK YOU!</Text>
                        <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 18, lineHeight: 27, letterSpacing: 1.2, color: '#333333'}}>Help us further by sharing on</Text>
                        <View style={{flexDirection: 'row', justifyContent: 'center', paddingVertical: 20}}>
                          <Icon
                            name='facebook-f'
                            type='font-awesome'
                            reverse
                            iconStyle={{fontSize: 40}}
                            underlayColor="blue"
                            color="#4468A3"
                            size={30}
                            containerStyle={{marginRight: 15}}
                            onPress={this.props.handleShare('facebook', true)}
                          />
                          <Icon
                            reverse
                            name='twitter'
                            type='font-awesome'
                            color='#f50'
                            color="#1DA1F2"
                            size={30}
                            iconStyle={{fontSize: 40}}
                            containerStyle={{marginLeft: 15}}
                            onPress={this.props.handleShare('twitter', true)}
                          />
                        </View>
                        <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#333333', textAlign: 'center'}}>
                          Your donation was received and you will receive an email receipt shortly. If you want to help us more you can share this fundraiser with others on social media!
                        </Text>
                      </View>
                    </View>
                  </MediaQuery>
                </>
              }
              {
                !this.state.submittedSuccessfully &&
                <ScrollView style={{backgroundColor: '#FFFFFF', flex: 1}}>
                  <View style={{height: '6%', flexDirection: 'row', alignItems: 'center', paddingHorizontal: '5%', justifyContent: 'space-between'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 24, lineHeight: 36, letterSpacing: 1.15, color: '#333333'}}>MAKE A DONATION</Text>
                    <Icon
                      type="antdesign"
                      name="close"
                      onPress={this.props.handleClose()}
                    />
                  </View>
                  <Divider/>
                  <Formik
                    initialValues={{
                      ...this.defaults,
                      ...this.props.data,
                      // user: { 
                      //     value: this.props.data?.user?.id,
                      //     label: `${this.props.data?.user?.firstName} ${this.props.data?.user?.lastName}`
                      // },
                      user: this.props.data?.user?.id,
                      identityDisplayType: 0
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                      try {
                        await handleSubmit(values);
                        this.setState({
                          submittedSuccessfully: true
                        }, resetForm());
                      } catch(err) {
                        console.log("ERROR IN MODAL", err);
                        toast.error(err.message, {
                          position: "bottom-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  >
                    {({ values, handleSubmit, isSubmitting }) => {
                      console.log("VALUES", values);
                      return (
                        <View style={{paddingHorizontal: '5%', flex: 1}}>
                          <View style={{height: height * 0.18, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 99}}>
                            <Text style={{fontFamily: 'helvetica-regular', fontSize: 20, lineHeight: 36, letterSpacing: 0.96, color: '#333333', marginBottom: 10}}>You are donating on behalf of:</Text>
                            <FastField name="user">
                              {({ field, form, meta }) => (
                                <Dropdown
                                  data={this.props.data?.memberships.map(membership => ({ label: `${membership.userFirst} ${membership.userLast}`, value: membership.user }))}
                                  onChange={(option) => {
                                    form.setFieldValue(field.name, option.value)
                                  }}
                                  value={Array.isArray(field.value) ? field.value.value :  field.value}
                                  placeholder="Select an option"
                                  dropdownContainerStyle={{width: '90%'}}
                                />
                              )}
                            </FastField>
                          </View>
                          <Divider/>
                          <View style={{height: height * 0.2, marginTop: 20, marginBottom: 40}}>
                            <Text style={{fontFamily: 'helvetica-neue-bold', color: '#333333', fontSize: 24, lineHeight: 36}}>Donation Amount</Text>
                            <View style={{flexDirection: 'row', flex: 1, marginTop: 10}}>
                              <View style={{width: '50%', borderRightWidth: 1, borderRightColor: '#DEDEDE'}}>
                                <FastField name="donationAmount">
                                  {({ field, form, meta }) => (
                                    <Input
                                      name="donationAmount"
                                      inputContainerStyle={{borderRadius: 5}}
                                      placeholder="0.00"
                                      placeholderTextColor="#DEDEDE"
                                      leftIcon={<Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 16, fontStyle: "bold", color: '#DEDEDE'}}>$</Text>}
                                      rightIcon={<Text style={{fontFamily: 'helvetica-regular', fontSize: 16, color: '#DEDEDE'}}>USD</Text>}
                                      containerStyle={{width: '90%'}}
                                      inputStyle={{paddingLeft: 10, fontFamily: 'helvetica-neue-bold', color: '#333333', fontSize: 16}}
                                      value={this.state.donationAmount}
                                      keyboardType="number-pad"
                                      value={field.value}
                                      error={meta.error}
                                      onChange={(value, name)=>{
                                        form.setFieldValue(field.name, value.nativeEvent.text);
                                      }}
                                      ref={c => {this.donationAmountFormInput = c}}
                                    />
                                  )}
                                </FastField>
                                <View style={{flexDirection: 'row', width: '90%'}}>
                                  <Field name="willPayProcessingFee">
                                    {({ field, form, meta }) => (
                                      <CheckBox
                                        checked={field.value}
                                        checkedIcon="check"
                                        checkedColor={getContrast(theme?.primary) === 'black' ? '#000000' : '#FFFFFF'}
                                        uncheckedColor="#FFFFFF"
                                        uncheckedIcon="close"
                                        iconType="antdesign"
                                        containerStyle={(() => {
                                          const checkedStyle = { backgroundColor: theme?.primary || '#00A5FF', borderWidth: 1, borderColor: theme?.primary || '#00A5FF', marginLeft: 0, height: 24 };
                                          const uncheckedStyle = { backgroundColor: '#FFFFFF', borderWidth: 1, borderColor: '#000000', marginLeft: 0, height: 24 };

                                          const pickedStyle = field.value ? checkedStyle : uncheckedStyle

                                          return (
                                            [{width: 25, height: '100%', alignItems: 'center', justifyContent: 'center'}, pickedStyle]
                                          );
                                        })()}
                                        size={14}
                                        onPress={()=> {
                                          form.setFieldValue(field.name, !field.value);
                                        }}
                                      />
                                    )}
                                  </Field>
                                  <Text style={{fontFamily: 'helvetica-neue-medium', fontSize: 11}}>
                                    {`Please charge me a total of $${(Number(values.donationAmount) + Number(values.donationAmount) * 0.042).toFixed(2)} to cover processing fees.`}
                                  </Text>
                                </View>
                              </View>
                              <View style={{width: '50%', borderLeftWidth: 1, borderLeftColor: '#DEDEDE', alignItems: 'flex-end'}}>
                              <FastField name="donationAmount">
                                  {({ field, form, meta }) => (
                                    <>
                                      <View style={{width: '90%', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10}}>
                                        <Button
                                          title="$35"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 35 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 16,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 35 ? (getContrast(theme?.primary) === 'black' ? '#000000' : '#FFFFFF') : '#000000'
                                          }}
                                          type={Number(field.value) === 35 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 35);
                                          }}
                                        />
                                        <Button
                                          title="$50"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 50 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 16,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 50 ? (getContrast(theme?.primary) === 'black' ? '#000000' : '#FFFFFF') : '#000000'
                                          }}
                                          type={Number(field.value) === 50 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 50);
                                          }}
                                        />
                                        <Button
                                          title="$100"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 100 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 16,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 100 ? (getContrast(theme?.primary) === 'black' ? '#000000' : '#FFFFFF') : '#000000'
                                          }}
                                          type={Number(field.value) === 100 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 100);
                                          }}
                                        />
                                      </View>
                                      <View style={{width: '90%', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Button
                                          title="$250"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 250 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 16,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 250 ? (getContrast(theme?.primary) === 'black' ? '#000000' : '#FFFFFF') : '#000000'
                                          }}
                                          type={Number(field.value) === 250 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 250);
                                          }}
                                        />
                                        <Button
                                          title="$500"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 500 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 16,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 500 ? (getContrast(theme?.primary) === 'black' ? '#000000' : '#FFFFFF') : '#000000'
                                          }}
                                          type={Number(field.value) === 500 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 500);
                                          }}
                                        />
                                        <Button
                                          title="CUSTOM"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: (Number(field.value) !== 35
                                            && Number(field.value) !== 50
                                            && Number(field.value) !== 100
                                            && Number(field.value) !== 250
                                            && Number(field.value) !== 500) ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 8,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: (Number(field.value) !== 35
                                            && Number(field.value) !== 50
                                            && Number(field.value) !== 100
                                            && Number(field.value) !== 250
                                            && Number(field.value) !== 500) ? (getContrast(theme?.primary) === 'black' ? '#000000' : '#FFFFFF') : '#000000'
                                          }}
                                          type={(Number(field.value) !== 35
                                            && Number(field.value) !== 50
                                            && Number(field.value) !== 100
                                            && Number(field.value) !== 250
                                            && Number(field.value) !== 500) ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, "");
                                            // this.donationAmountFormInput.inputRef.focus();
                                          }}
                                        />
                                      </View>
                                    </>
                                  )}
                                </FastField>
                              </View>
                            </View>
                          </View>
                          <Divider/>
                          <View style={{marginTop: 20, marginBottom: 20, zIndex: 1}}>
                            <Text style={{fontFamily: 'helvetica-neue-bold', color: '#333333', fontSize: 24, lineHeight: 36}}>Donation Details</Text>
                            <View style={{marginTop: 20}}>
                              <FastField name="customer.name">
                                {({ field, form, meta }) => (
                                  <Input
                                    name="name"
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Name"
                                    placeholderTextColor="#DEDEDE"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    label="Name"
                                    labelStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <FastField name="customer.email">
                                {({ field, form, meta }) => (
                                  <Input
                                    name="email"
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Email"
                                    placeholderTextColor="#DEDEDE"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    label="Email"
                                    labelStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <FastField name="message">
                                {({ field, form, meta }) => (
                                  <Input
                                    name="message"
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 15}}
                                    placeholder="Type a message to be displayed with your donation on the recipient's fundraising page"
                                    placeholderTextColor="#C3C3C3"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    multiline
                                    numberOfLines={9}
                                    label="Message"
                                    labelStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 16, paddingBottom: 5}}>Display Name Options</Text>
                              <FastField name="identityDisplayType">
                                {({ field, form, meta }) => (
                                  // <Dropdown
                                  //   data={[{ label: 'Display my name', value: 0 }, { label: 'Remain anonymous', value: 1 }]}
                                  //   onChange={({ value }) => {
                                  //     form.setFieldValue(field.name, value)
                                  //   }}
                                  //   value={field.value}
                                  //   openMenuOnClick={true}
                                  //   blurOnSubmit={false}
                                  //   placeholder="Select a Display Type"
                                  //   placeholderTextColor="#DEDEDE"
                                  //   inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                  // />
                                  <Dropdown
                                    // data={[{ label: 'Display my name', value: 0 }, { label: 'Remain anonymous', value: 1 }]}
                                    // onChange={(option) => {
                                    //   console.log("OPTION", option);
                                    //   form.setFieldValue(field.name, option.value)
                                    // }}
                                    // value={[{ label: 'Display my name', value: 0 }, { label: 'Remain anonymous', value: 1 }].filter(({value}) => value === field.value)[0]}
                                    // placeholder="Select an option"
                                    // // dropdownContainerStyle={{width: '48%'}}
                                    data={IDENTITY_DISPLAY_OPTIONS}
                                    onChange={(option) => {
                                      form.setFieldValue(field.name, option.value)
                                    }}
                                    value={field.value || 0}
                                    error={meta.error}
                                    placeholder="Select an option"
                                  />
                                )}
                              </FastField>
                            </View>
                          </View>
                          <Divider/>
                          <View style={{marginTop: 20}}>
                            <Text style={{fontFamily: 'helvetica-neue-bold', color: '#333333', fontSize: 24, lineHeight: 36}}>Payment Info</Text>
                            <View style={{marginBottom: 40, marginTop: 20}}>
                              <View style={{marginBottom: 15, zIndex: 99}}>
                                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 16, paddingBottom: 5}}>Address</Text>
                                <View style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
                                  <FastField name="customer.address">
                                    {({ field, form, meta }) => (
                                      <Input
                                        inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                        placeholder="Address"
                                        placeholderTextColor="#DEDEDE"
                                        style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                        containerStyle={{width: '80%'}}
                                        value={field.value}
                                        error={meta.error}
                                        onChange={(value, name)=>{
                                          form.setFieldValue(field.name, value.nativeEvent.text);
                                        }}
                                      />
                                    )}
                                  </FastField>
                                  <FastField name="customer.apt">
                                    {({ field, form, meta }) => (
                                      <Input
                                        inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                        placeholder="Apt"
                                        placeholderTextColor="#DEDEDE"
                                        style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                        containerStyle={{width: '18%'}}
                                        value={field.value}
                                        error={meta.error}
                                        onChange={(value, name)=>{
                                          form.setFieldValue(field.name, value.nativeEvent.text);
                                        }}
                                      />
                                    )}
                                  </FastField>
                                </View>
                                <View style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between', zIndex: 99}}>
                                  <FastField name="customer.city">
                                    {({ field, form, meta }) => (
                                      <Input
                                        inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                        placeholder="City"
                                        placeholderTextColor="#DEDEDE"
                                        style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                        containerStyle={{width: '55%'}}value={field.value}
                                        value={field.value}
                                        error={meta.error}
                                        onChange={(value, name)=>{
                                          form.setFieldValue(field.name, value.nativeEvent.text);
                                        }}
                                      />
                                    )}
                                  </FastField>
                                  <FastField name="customer.state">
                                    {({ field, form, meta }) => (
                                      // <Dropdown
                                      //   data={STATES}
                                      //   onChange={({ value }) => {
                                      //     form.setFieldValue(field.name, value)
                                      //   }}
                                      //   value={field.value}
                                      //   openMenuOnClick={true}
                                      //   blurOnSubmit={false}
                                      //   placeholder="State"
                                      //   placeholderTextColor="#DEDEDE"
                                      //   inputContainerStyle={{borderRadius: 5, paddingVertical: 0}}
                                      //   dropdownContainerStyle={{width: '20%'}}
                                      // />
                                      <Dropdown
                                        // data={STATES}
                                        // onChange={(option) => {
                                        //   form.setFieldValue(field.name, option.value)
                                        // }}
                                        // value={field.value}
                                        // placeholder="State"
                                        // dropdownContainerStyle={{width: '20%', zIndex: 99, height: '100%'}}
                                          data={STATES}
                                          onChange={(option) => {
                                            form.setFieldValue(field.name, option.value)
                                          }}
                                          value={field.value}
                                          error={meta.error}
                                          placeholder="State"
                                          dropdownContainerStyle={{width: '40%'}}
                                          inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                          placeholderTextColor="#DEDEDE"
                                          style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                          menuClassName="menu-option-container"
                                      />
                                    )}
                                  </FastField>
                                </View>
                                <FastField name="customer.zipCode">
                                  {({ field, form, meta }) => (
                                    <Input
                                      inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                      placeholder="Zip"
                                      placeholderTextColor="#C3C3C3"
                                      style={{fontFamily: 'helvetica-regular', fontSize: 16, width: '100%'}}
                                      containerStyle={{width: '100%'}}
                                      value={field.value}
                                      error={meta.error}
                                      onChange={(value, name)=>{
                                        form.setFieldValue(field.name, value.nativeEvent.text);
                                      }}
                                    />
                                  )}
                                </FastField>
                              </View>
                              <FastField name="customer.phone">
                                {({ field, form, meta }) => (
                                  <Input
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Phone Number"
                                    placeholderTextColor="#C3C3C3"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    label="Phone Number"
                                    labelStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                            </View>
                            <View style={{marginBottom: 30, zIndex: 99}}>
                              <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 16, paddingBottom: 5}}>Card Information</Text>
                              <FastField name="cardName">
                                {({ field, form, meta }) => (
                                  <Input
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Name on card"
                                    placeholderTextColor="#C3C3C3"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <FastField name="cardNumber">
                                {({ field, form, meta }) => (
                                  <Input
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Card Number"
                                    placeholderTextColor="#C3C3C3"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <View style={{flexDirection: 'row'}}>
                                <FastField name="expiryMonth">
                                  {({ field, form, meta }) => (
                                    // <Dropdown
                                    //   data={MONTHS}
                                    //   onChange={({ value }) => {
                                    //     form.setFieldValue(field.name, value)
                                    //   }}
                                    //   value={field.value}
                                    //   openMenuOnClick={true}
                                    //   blurOnSubmit={false}
                                    //   placeholder="MM"
                                    //   placeholderTextColor="#DEDEDE"
                                    //   inputContainerStyle={{borderRadius: 5}}
                                    //   dropdownContainerStyle={{width: '23%'}}
                                    //   value={field.value}
                                    //   error={meta.error}
                                    // />
                                    <Dropdown
                                      // data={MONTHS}
                                      // onChange={(option) => {
                                      //   form.setFieldValue(field.name, option.value)
                                      // }}
                                      // value={field.value}
                                      // placeholder="MM"
                                      // dropdownContainerStyle={{width: '30%', zIndex: 99, height: '100%'}}
                                      // menuClassName="menu-option-container"
                                      data={MONTHS}
                                      onChange={(option) => {
                                        form.setFieldValue(field.name, option.value)
                                      }}
                                      value={field.value}
                                      error={meta.error}
                                      placeholder="MM"
                                      placeholderTextColor="#DEDEDE"
                                      dropdownContainerStyle={{width: '30%', height: '100%'}}
                                      inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                      menuClassName="menu-option-container"
                                      style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    />
                                  )}
                                </FastField>
                                <FastField name="expiryYear">
                                  {({ field, form, meta }) => (
                                    // <Dropdown
                                    //   data={[{label: '2021', value: 2021}, {label: '2022', value: 2022}, {label: '2023', value: 2023}, {label: '2024', value: 2024}, {label: '2025', value: 2025}]}
                                    //   onChange={({ value }) => {
                                    //     form.setFieldValue(field.name, value)
                                    //   }}
                                    //   value={field.value}
                                    //   openMenuOnClick={true}
                                    //   blurOnSubmit={false}
                                    //   placeholder="YYYY"
                                    //   placeholderTextColor="#DEDEDE"
                                    //   inputContainerStyle={{borderRadius: 5}}
                                    //   dropdownContainerStyle={{width: '23%', marginLeft: 15}}
                                    //   value={field.value}
                                    //   error={meta.error}
                                    // />
                                    <Dropdown
                                      // data={generateYearList(null, 7)}
                                      // onChange={(option) => {
                                      //   form.setFieldValue(field.name, option.value)
                                      // }}
                                      // value={generateYearList(null, 7).filter(({value}) => value === field.value)[0]}
                                      // placeholder="YYYY"
                                      // dropdownContainerStyle={{width: '30%', marginLeft: 15, zIndex: 99, height: '100%'}}
                                      // menuClassName="menu-option-container"
                                      data={YEARS}
                                      onChange={(option) => {
                                        form.setFieldValue(field.name, option.value)
                                      }}
                                      value={field.value}
                                      error={meta.error}
                                      placeholder="YYYY"
                                      placeholderTextColor="#DEDEDE"
                                      dropdownContainerStyle={{width: '30%', marginLeft: 15, height: '100%'}}
                                      inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                      menuClassName="menu-option-container"
                                      style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    />
                                  )}
                                </FastField>
                                <FastField name="cvc">
                                  {({ field, form, meta }) => (
                                    <Input
                                      inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                      placeholder="CVC"
                                      placeholderTextColor="#DEDEDE"
                                      style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                      containerStyle={{width: '30%', marginLeft: 15}}
                                      value={field.value}
                                      error={meta.error}
                                      onChange={(value, name)=>{
                                        form.setFieldValue(field.name, value.nativeEvent.text);
                                      }}
                                    />
                                  )}
                                </FastField>
                              </View>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                              <Button
                                title="Donate Now"
                                buttonStyle={{backgroundColor: theme?.primary || '#086B3A', borderRadius: 4, paddingVertical: 15}}
                                titleStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 18, lineHeight: 23, letterSpacing: 0.86, color: '#FFFFFF'}}
                                containerStyle={{width: '50%'}}
                                onPress={handleSubmit}
                                loading={isSubmitting}
                              />
                              <View style={{paddingLeft: 20, justifyContent: 'center'}}>
                                <Text style={{color: '#DEDEDE', fontFamily: 'helvetica-regular'}}>Amount:</Text>
                                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 24, color: '#333333'}}>
                                  {
                                    values.willPayProcessingFee ?
                                    `$${(Number(values.donationAmount) + (Number(values.donationAmount) * 0.042)).toFixed(2)}` :
                                    `$${Number(values.donationAmount).toFixed(2)}`
                                  }
                                </Text>
                              </View>
                            </View>
                            <View style={{marginTop: 40}}></View>
                          </View>
                        </View>
                      )
                    }}
                  </Formik>
                </ScrollView>
              }
            </View>
          </Modal>
        </MediaQuery>
        <MediaQuery minDeviceWidth={481}>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.props.data.isVisible === undefined ? false : this.props.data.isVisible}
            onDismiss={() => {
              console.log("DISMISSING MODAL");
            }}
          >
            <View style={{flex: 1, paddingHorizontal: width * 0.3, height: '100%', backgroundColor: 'rgba(0,0,0,0.75)'}}>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              {
                this.state.submittedSuccessfully &&
                <MediaQuery minDeviceWidth={481}>
                  <View style={{flex: 1, backgroundColor: '#FFFFFF', alignItems: 'center', paddingHorizontal: width * 0.05}}>
                    <View style={{height: height * 0.05, width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                      <Icon
                        type="antdesign"
                        name="close"
                        onPress={this.props.handleClose()}
                      />
                    </View>
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                      <Avatar
                        rounded
                        source={{ uri: this.props.data.membership?.userImage || this.props.data.user?.image }}
                        size={125}
                        containerStyle={{
                          // borderWidth: 2,
                          // borderColor: campaign?.theme.secondary
                        }}
                      />
                      <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 25, lineHeight: 30, letterSpacing: 1.2, color: '#333333', paddingVertical: 20}}>THANK YOU!</Text>
                      <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 18, lineHeight: 27, letterSpacing: 1.2, color: '#333333'}}>Help us further by sharing on</Text>
                      <View style={{flexDirection: 'row', justifyContent: 'center', paddingVertical: 20}}>
                        <Icon
                          name='facebook-f'
                          type='font-awesome'
                          reverse
                          iconStyle={{fontSize: 40}}
                          underlayColor="blue"
                          color="#4468A3"
                          size={30}
                          containerStyle={{marginRight: 15}}
                          onPress={this.props.handleShare('facebook')}
                        />
                        <Icon
                          reverse
                          name='twitter'
                          type='font-awesome'
                          color='#f50'
                          color="#1DA1F2"
                          size={30}
                          iconStyle={{fontSize: 40}}
                          containerStyle={{marginLeft: 15}}
                          onPress={this.props.handleShare('twitter')}
                        />
                      </View>
                      <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#333333', textAlign: 'center'}}>
                        Your donation was received and you will receive an email receipt shortly. If you want to help us more you can share this fundraiser with others on social media!
                      </Text>
                    </View>
                  </View>
                </MediaQuery>
              }
              {
                !this.state.submittedSuccessfully &&
                <ScrollView style={{backgroundColor: '#FFFFFF', flex: 1}}>
                  <View style={{height: '6%', flexDirection: 'row', alignItems: 'center', paddingHorizontal: '5%', justifyContent: 'space-between'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 24, lineHeight: 36, letterSpacing: 1.15, color: '#333333'}}>MAKE A DONATION</Text>
                    <Icon
                      type="antdesign"
                      name="close"
                      onPress={this.props.handleClose()}
                    />
                  </View>
                  <Divider/>
                  <Formik
                    initialValues={{
                      ...this.defaults,
                      ...this.props.data,
                      // user: { 
                      //     value: this.props.data?.user?.id,
                      //     label: `${this.props.data?.user?.firstName} ${this.props.data?.user?.lastName}`
                      // },
                      user: this.props.data?.user?.id,
                      identityDisplayType: 0
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                      try {
                        await handleSubmit(values);
                        this.setState({
                          submittedSuccessfully: true
                        }, () => {
                          console.log("STATE AFTER SUBMIT", this.state);
                          resetForm()
                        });
                      } catch(err) {
                        console.log("ERROR IN MODAL", err);
                        toast.error(err.message, {
                          position: "bottom-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  >
                    {({ values, handleSubmit, isSubmitting }) => {
                      console.log("VALUES", values);
                      return (
                        <View style={{paddingHorizontal: '5%', flex: 1}}>
                          <View style={{height: height * 0.12, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', zIndex: 99}}>
                            <View style={{flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
                              <Text style={{fontFamily: 'helvetica-regular', fontSize: 20, lineHeight: 36, letterSpacing: 0.96, color: '#333333'}}>You are donating on behalf of:</Text>
                              <FastField name="user">
                                {({ field, form, meta }) => (
                                  // <Dropdown
                                  //   data={this.props.data.memberships.map(membership => ({ label: `${membership.userFirst} ${membership.userLast}`, value: membership.user }))}
                                  //   onChange={({ value }) => {
                                  //     form.setFieldValue(field.name, value)
                                  //   }}
                                  //   value={field.value}
                                  //   openMenuOnClick={true}
                                  //   blurOnSubmit={false}
                                  //   placeholder="Select a Student"
                                  //   placeholderTextColor="#DEDEDE"
                                  //   inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                  //   dropdownContainerStyle={{width: '49%'}}
                                  //   value={field.value}
                                  //   error={meta.error}
                                  // />
                                  <Dropdown
                                    data={this.props.data?.memberships.map(membership => ({ label: `${membership.userFirst} ${membership.userLast}`, value: membership.user }))}
                                    onChange={(option) => {
                                      form.setFieldValue(field.name, option.value)
                                    }}
                                    value={Array.isArray(field.value) ? field.value.value :  field.value}
                                    placeholder="Select an option"
                                    dropdownContainerStyle={{width: '48%'}}
                                  />
                                )}
                              </FastField>
                            </View>
                          </View>
                          <Divider/>
                          <View style={{height: height * 0.2, marginTop: 20, marginBottom: 40}}>
                            <Text style={{fontFamily: 'helvetica-neue-bold', color: '#333333', fontSize: 24, lineHeight: 36}}>Donation Amount</Text>
                            <View style={{flexDirection: 'row', flex: 1, marginTop: 10}}>
                              <View style={{width: '50%', borderRightWidth: 1, borderRightColor: '#DEDEDE'}}>
                                <FastField name="donationAmount">
                                  {({ field, form, meta }) => (
                                    <Input
                                      name="donationAmount"
                                      inputContainerStyle={{borderRadius: 5}}
                                      placeholder="0.00"
                                      placeholderTextColor="#DEDEDE"
                                      leftIcon={<Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 24, fontStyle: "bold", color: '#DEDEDE'}}>$</Text>}
                                      rightIcon={<Text style={{fontFamily: 'helvetica-regular', fontSize: 20, color: '#DEDEDE'}}>USD</Text>}
                                      containerStyle={{width: '90%'}}
                                      inputStyle={{paddingLeft: 10, fontFamily: 'helvetica-neue-bold', color: '#333333', fontSize: 24}}
                                      value={this.state.donationAmount}
                                      keyboardType="number-pad"
                                      value={field.value}
                                      error={meta.error}
                                      onChange={(value, name)=>{
                                        form.setFieldValue(field.name, value.nativeEvent.text);
                                      }}
                                    />
                                  )}
                                </FastField>
                                <View style={{flexDirection: 'row', width: '90%'}}>
                                  <FastField name="willPayProcessingFee">
                                    {({ field, form, meta }) => (
                                      <CheckBox
                                        checked={field.value}
                                        checkedIcon="check"
                                        checkedColor="#FFFFFF"
                                        uncheckedColor="#FFFFFF"
                                        uncheckedIcon="close"
                                        iconType="antdesign"
                                        containerStyle={(() => {
                                          const checkedStyle = { backgroundColor: theme?.primary || '#00A5FF', borderWidth: 1, borderColor: theme?.primary || '#00A5FF', marginLeft: 0, height: 24 };
                                          const uncheckedStyle = { backgroundColor: '#FFFFFF', borderWidth: 1, borderColor: '#000000', marginLeft: 0, height: 24 };

                                          const pickedStyle = field.value ? checkedStyle : uncheckedStyle

                                          return (
                                            [{width: 25, height: '100%', alignItems: 'center', justifyContent: 'center'}, pickedStyle]
                                          );
                                        })()}
                                        size={14}
                                        onPress={()=> {
                                          form.setFieldValue(field.name, !field.value);
                                        }}
                                      />
                                    )}
                                  </FastField>
                                  <Text style={{fontFamily: 'helvetica-neue-medium', fontSize: 14}}>
                                    {`Please charge me a total of $${(Number(values.donationAmount) + Number(values.donationAmount) * 0.042).toFixed(2)} to cover processing fees.`}
                                  </Text>
                                </View>
                              </View>
                              <View style={{width: '50%', borderLeftWidth: 1, borderLeftColor: '#DEDEDE', alignItems: 'flex-end'}}>
                              <FastField name="donationAmount">
                                  {({ field, form, meta }) => (
                                    <>
                                      <View style={{width: '90%', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10}}>
                                        <Button
                                          title="$35"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 35 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 18,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 35 ? '#FFFFFF' : '#000000'
                                          }}
                                          type={Number(field.value) === 35 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 35);
                                          }}
                                        />
                                        <Button
                                          title="$50"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 50 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 18,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 50 ? '#FFFFFF' : '#000000'
                                          }}
                                          type={Number(field.value) === 50 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 50);
                                          }}
                                        />
                                        <Button
                                          title="$100"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 100 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 18,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 100 ? '#FFFFFF' : '#000000'
                                          }}
                                          type={Number(field.value) === 100 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 100);
                                          }}
                                        />
                                      </View>
                                      <View style={{width: '90%', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Button
                                          title="$250"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 250 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 18,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 250 ? '#FFFFFF' : '#000000'
                                          }}
                                          type={Number(field.value) === 250 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 250);
                                          }}
                                        />
                                        <Button
                                          title="$500"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: Number(field.value) === 500 ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 18,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: Number(field.value) === 500 ? '#FFFFFF' : '#000000'
                                          }}
                                          type={Number(field.value) === 500 ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, 500);
                                          }}
                                        />
                                        <Button
                                          title="CUSTOM"
                                          type="outline"
                                          buttonStyle={{
                                            borderColor: theme?.primary || '#086B3A',
                                            borderWidth: 2,
                                            borderRadius: 4,
                                            paddingVertical: 10,
                                            backgroundColor: (Number(field.value) !== 35
                                            && Number(field.value) !== 50
                                            && Number(field.value) !== 100
                                            && Number(field.value) !== 250
                                            && Number(field.value) !== 500) ? theme?.primary || '#086B3A' : '#FFFFFF'
                                          }}
                                          titleStyle={{
                                            fontFamily: 'helvetica-neue-bold',
                                            fontSize: 14,
                                            lineHeight: 23,
                                            letterSpacing: 0.86,
                                            color: (Number(field.value) !== 35
                                            && Number(field.value) !== 50
                                            && Number(field.value) !== 100
                                            && Number(field.value) !== 250
                                            && Number(field.value) !== 500) ? (getContrast(theme?.primary) === 'black' ? '#000000' : '#FFFFFF') : '#000000'
                                          }}
                                          type={(Number(field.value) !== 35
                                            && Number(field.value) !== 50
                                            && Number(field.value) !== 100
                                            && Number(field.value) !== 250
                                            && Number(field.value) !== 500) ? undefined : 'outline'}
                                          containerStyle={{width: '31%'}}
                                          onPress={() => {
                                            form.setFieldValue(field.name, "");
                                          }}
                                        />
                                      </View>
                                    </>
                                  )}
                                </FastField>
                              </View>
                            </View>
                          </View>
                          <Divider/>
                          <View style={{marginTop: 20, marginBottom: 20, zIndex: 1}}>
                            <Text style={{fontFamily: 'helvetica-neue-bold', color: '#333333', fontSize: 24, lineHeight: 36}}>Donation Details</Text>
                            <View style={{marginTop: 20}}>
                              <FastField name="customer.name">
                                {({ field, form, meta }) => (
                                  <Input
                                    name="name"
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Name"
                                    placeholderTextColor="#DEDEDE"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    label="Name"
                                    labelStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <FastField name="customer.email">
                                {({ field, form, meta }) => (
                                  <Input
                                    name="email"
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Email"
                                    placeholderTextColor="#DEDEDE"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    label="Email"
                                    labelStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <FastField name="message">
                                {({ field, form, meta }) => (
                                  <Input
                                    name="message"
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 15}}
                                    placeholder="Type a message to be displayed with your donation on the recipient's fundraising page"
                                    placeholderTextColor="#C3C3C3"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    multiline
                                    numberOfLines={9}
                                    label="Message"
                                    labelStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 16, paddingBottom: 5}}>Display Name Options</Text>
                              <FastField name="identityDisplayType">
                                {({ field, form, meta }) => (
                                  <Dropdown
                                    data={IDENTITY_DISPLAY_OPTIONS}
                                    onChange={(option) => {
                                      form.setFieldValue(field.name, option.value)
                                    }}
                                    value={field.value || 0}
                                    error={meta.error}
                                    placeholder="Select an option"
                                  />
                                )}
                              </FastField>
                            </View>
                          </View>
                          <Divider/>
                          <View style={{marginTop: 20}}>
                            <Text style={{fontFamily: 'helvetica-neue-bold', color: '#333333', fontSize: 24, lineHeight: 36}}>Payment Info</Text>
                            <View style={{marginBottom: 40, marginTop: 20}}>
                              <View style={{marginBottom: 15, zIndex: 99}}>
                                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 16, paddingBottom: 5}}>Address</Text>
                                <View style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
                                  <FastField name="customer.address">
                                    {({ field, form, meta }) => (
                                      <Input
                                        inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                        placeholder="Address"
                                        placeholderTextColor="#DEDEDE"
                                        style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                        containerStyle={{width: '80%'}}
                                        value={field.value}
                                        error={meta.error}
                                        onChange={(value, name)=>{
                                          form.setFieldValue(field.name, value.nativeEvent.text);
                                        }}
                                      />
                                    )}
                                  </FastField>
                                  <FastField name="customer.apt">
                                    {({ field, form, meta }) => (
                                      <Input
                                        inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                        placeholder="Apt"
                                        placeholderTextColor="#DEDEDE"
                                        style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                        containerStyle={{width: '18%'}}
                                        value={field.value}
                                        error={meta.error}
                                        onChange={(value, name)=>{
                                          form.setFieldValue(field.name, value.nativeEvent.text);
                                        }}
                                      />
                                    )}
                                  </FastField>
                                </View>
                                <View style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
                                  <FastField name="customer.city">
                                    {({ field, form, meta }) => (
                                      <Input
                                        inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                        placeholder="City"
                                        placeholderTextColor="#DEDEDE"
                                        style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                        containerStyle={{width: '55%'}}
                                        value={field.value}
                                        error={meta.error}
                                        onChange={(value, name)=>{
                                          form.setFieldValue(field.name, value.nativeEvent.text);
                                        }}
                                      />
                                    )}
                                  </FastField>
                                  <FastField name="customer.state">
                                    {({ field, form, meta }) => (
                                      <Dropdown
                                        data={STATES}
                                        onChange={(option) => {
                                          form.setFieldValue(field.name, option.value)
                                        }}
                                        value={field.value}
                                        error={meta.error}
                                        placeholder="State"
                                        dropdownContainerStyle={{width: '20%'}}
                                        inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                        placeholderTextColor="#DEDEDE"
                                        style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                        menuClassName="menu-option-container"
                                      />
                                    )}
                                  </FastField>
                                  <FastField name="customer.zipCode">
                                    {({ field, form, meta }) => (
                                      <Input
                                        inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                        placeholder="Zip/Postal"
                                        placeholderTextColor="#C3C3C3"
                                        style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                        containerStyle={{width: '20%'}}
                                        value={field.value}
                                        error={meta.error}
                                        onChange={(value, name)=>{
                                          form.setFieldValue(field.name, value.nativeEvent.text);
                                        }}
                                      />
                                    )}
                                  </FastField>
                                </View>
                              </View>
                              <FastField name="customer.phone">
                                {({ field, form, meta }) => (
                                  <Input
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Phone Number"
                                    placeholderTextColor="#C3C3C3"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    label="Phone Number"
                                    labelStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                            </View>
                            <View style={{marginBottom: 30, zIndex: 99}}>
                              <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 16, paddingBottom: 5}}>Card Information</Text>
                              <FastField name="cardName">
                                {({ field, form, meta }) => (
                                  <Input
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Name on card"
                                    placeholderTextColor="#C3C3C3"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <FastField name="cardNumber">
                                {({ field, form, meta }) => (
                                  <Input
                                    inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                    placeholder="Card Number"
                                    placeholderTextColor="#C3C3C3"
                                    style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    value={field.value}
                                    error={meta.error}
                                    onChange={(value, name)=>{
                                      form.setFieldValue(field.name, value.nativeEvent.text);
                                    }}
                                  />
                                )}
                              </FastField>
                              <View style={{flexDirection: 'row'}}>
                                <FastField name="expiryMonth">
                                  {({ field, form, meta }) => (
                                    <Dropdown
                                      data={MONTHS}
                                      onChange={(option) => {
                                        form.setFieldValue(field.name, option.value)
                                      }}
                                      value={field.value}
                                      error={meta.error}
                                      placeholder="MM"
                                      placeholderTextColor="#DEDEDE"
                                      dropdownContainerStyle={{width: '23%', height: '100%'}}
                                      inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                      menuClassName="menu-option-container"
                                      style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    />
                                  )}
                                </FastField>
                                <FastField name="expiryYear">
                                  {({ field, form, meta }) => (
                                    <Dropdown
                                      data={YEARS}
                                      onChange={(option) => {
                                        form.setFieldValue(field.name, option.value)
                                      }}
                                      value={field.value}
                                      error={meta.error}
                                      placeholder="YYYY"
                                      placeholderTextColor="#DEDEDE"
                                      dropdownContainerStyle={{width: '23%', marginLeft: 15, height: '100%'}}
                                      inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                      menuClassName="menu-option-container"
                                      style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                    />
                                  )}
                                </FastField>
                                <FastField name="cvc">
                                  {({ field, form, meta }) => (
                                    <Input
                                      inputContainerStyle={{borderRadius: 5, paddingVertical: 5}}
                                      placeholder="CVC"
                                      placeholderTextColor="#DEDEDE"
                                      style={{fontFamily: 'helvetica-regular', fontSize: 16}}
                                      containerStyle={{width: '23%', marginLeft: 15}}
                                      value={field.value}
                                      error={meta.error}
                                      onChange={(value, name)=>{
                                        form.setFieldValue(field.name, value.nativeEvent.text);
                                      }}
                                    />
                                  )}
                                </FastField>
                              </View>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                              <Button
                                title="Donate Now"
                                buttonStyle={{backgroundColor: theme?.primary || '#086B3A', borderRadius: 4, paddingVertical: 15}}
                                titleStyle={{fontFamily: 'helvetica-neue-bold', fontSize: 18, lineHeight: 23, letterSpacing: 0.86, color: '#FFFFFF'}}
                                containerStyle={{width: '50%'}}
                                onPress={handleSubmit}
                                loading={isSubmitting}
                              />
                              <View style={{paddingLeft: 20, justifyContent: 'center'}}>
                                <Text style={{color: '#DEDEDE', fontFamily: 'helvetica-regular'}}>Amount:</Text>
                                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 24, color: '#333333'}}>{
                                  values.willPayProcessingFee ?
                                  `$${(Number(values.donationAmount) + (Number(values.donationAmount) * 0.042)).toFixed(2)}` :
                                  `$${Number(values.donationAmount).toFixed(2)}`
                                }</Text>
                              </View>
                            </View>
                            <View style={{marginTop: 40}}></View>
                          </View>
                        </View>
                      )
                    }}
                  </Formik>
                </ScrollView>
              }
            </View>
          </Modal>
        </MediaQuery>
      </View>
    );
  }
}

export default CheckoutModal;