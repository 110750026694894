import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Input as RNEInput } from 'react-native-elements';

const Input = class Input extends Component {

  constructor(props) {
    super(props);

    this._handleOnChange = this._handleOnChange.bind(this)
  }

  _handleOnChange(evt) {
    this.props.onChange(evt, this.props.name);
  }

  render() {
    
    const {
      label,
      name,
      error,
      onChange,
      required = false,
      containerStyle = {},
      inputContainerStyle = {},
      inputStyle = {},
      labelStyle = {},
      value = '',
      showErrorMessage = false,
      ...rest
    } = this.props;

    return (
      <View style={containerStyle}>
        {
          label &&
          <Text style={[{paddingBottom: 5}, labelStyle]}>{label}</Text>
        }
        <RNEInput
          ref={c => this.inputRef = c}
          containerStyle={[styles.input]}
          inputContainerStyle={error ? [styles.innerInput, inputContainerStyle, styles.errorInnerInput] : [styles.innerInput, inputContainerStyle]}
          // label={required ? `${label}*` : label}
          labelStyle={error ? [styles.label, styles.errorLabel] : styles.label}
          inputStyle={[styles.inputStyle, inputStyle]}
          underlineColorAndroid="rgba(0,0,0,0)"
          selectionColor='#CCCCCC'
          onChange={this._handleOnChange}
          value={value}
          {...rest}
        />
        {/* { error && showErrorMessage &&
          <Text style={styles.errorMessage}>{error}</Text>
        } */}
      </View>
    );
  }

}

const styles = StyleSheet.create({
  input: {
    width: '100%',
    paddingHorizontal: 0
  },
  inputStyle: {
    color: '#999999',
    fontFamily: 'helvetica-neue-medium',
    fontSize: 14,
    width: '100%'
  },
  innerInput: {
    borderWidth: 2,
    borderColor: '#CCCCCC',
    borderRadius: 2,
    borderBottomWidth: 2,
    paddingHorizontal: 15
  },
  label: {
    backgroundColor: '#fff',
    color: '#393939',
    alignSelf: 'flex-start',
    paddingHorizontal: 5,
    marginHorizontal: 10,
    position: 'absolute',
    top: -10,
    left: 5,
    zIndex: 999,
    fontWeight: 'bold',
    fontFamily: 'helvetica-neue-medium',
    fontSize: 12
  },
  errorLabel: {
    color: '#FF0049'
  },
  errorInnerInput: {
    borderColor: '#FF0049'
  },
  errorMessage: {
    color: '#FF0049',
    fontSize: 11,
    lineHeight: 13,
    fontFamily: 'helvetica-neue-medium'
  }
})

export default Input;