import React, { useState } from 'react';
import { StyleSheet, View} from 'react-native';
import { Icon } from 'react-native-elements';
import {default as RDropdown} from 'react-dropdown';
import 'react-dropdown/style.css';
// import Select, { components } from 'react-select';

import '../index.css';

import Input from '../components/Input';

const Dropdown = (props) => {

  // const [menuIsOpen, setMenuIsOpen] = useState(false);

  const {
    blurOnSubmit = false,
    data = [],
    onChange,
    value,
    inputContainerStyle = {},
    style = {},
    placeholder = '',
    placeholderTextColor = '',
    dropdownContainerStyle = {},
    menuClassName = "",
    error
  } = props;

  const selection = data.find(datum => datum.value === value);

  return (
    <View style={[styles.inputContainerCompact, dropdownContainerStyle]}>
      <RDropdown
        options={data}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        menuClassName={menuClassName}
        name="state"
        className="dropdown-container"
        controlClassName="dropdown-control"
        autocomplete="address-level1"
      />
      <Input
        value={selection?.label || ""}
        inputStyle={styles.inputStyle}
        disabled
        disabledInputStyle={styles.disabledInputStyle}
        containerStyle={{position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 1, pointerEvents: 'none', backgroundColor: '#FFFFFF'}}
        rightIcon={<Icon name="caretdown" type="antdesign" color={error ? "red" : "#999999"} size={17}/>}
        rightIconContainerStyle={{marginVertical: 0}}
        blurOnSubmit={blurOnSubmit}
        style={style}
        error={error}
        inputContainerStyle={inputContainerStyle}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        pointerEvents="none"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainerCompact: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    height: undefined
  },
  inputStyle: {
    fontSize: 16,
    width: '100%',
    position: 'relative'
  },
  disabledInputStyle: {
    color: '#999999',
    opacity: 1
  }
});

export default Dropdown;