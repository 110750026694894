import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getContrast } from '../utilities/utilities';
import { clamp } from 'lodash-es';

const ProgressBar = (props) => {

  const {
    data,
    label,
    containerColor,
    barColor,
    fillText = '',
    barText = '',
    fillTextStyle = {},
    barTextStyle = {},
    barContainerStyle = {},
    textContainerStyle = {}
  } = props;

  console.log("DATA", data);

  const percentage = data?.value && data?.total ? clamp(data.value/data.total * 100, 0, 100) : 0;

  return (
    <View style={[styles.barContainer, { backgroundColor: containerColor}, barContainerStyle]}>
      <View style={[{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 9,
        paddingHorizontal: 5
      }, textContainerStyle]}>
        <Text style={[{
          color:'#FFFFFF',
          fontSize: 12,
          fontFamily: 'helvetica-neue-bold',
          lineHeight: 25,
          letterSpacing: 0.58}, fillTextStyle]}>
            {fillText}
        </Text>
        <Text style={[{
          color: getContrast(containerColor) === 'black' ? '#000000' : '#FFFFFF',
          fontSize: 12,
          fontFamily: 'helvetica-neue-bold',
          lineHeight: 25,
          letterSpacing: 0.58
        }, barTextStyle]}>
          {barText}
        </Text>
      </View>
      <View style={{width: `${percentage}%`, backgroundColor: barColor || '#086B3A', justifyContent: 'center'}}>
        {/* { percentage >= 15 &&
          <Text style={[{
            color:'#FFFFFF',
            fontSize: 12,
            fontFamily: 'helvetica-neue-bold',
            lineHeight: 25,
            letterSpacing: 0.58}, fillTextStyle]}>
              {fillText}
          </Text>
        } */}
      </View>
      <View style={{width: `${100 - percentage}%`, justifyContent: 'center', alignItems: 'flex-end'}}>
        {/* { percentage < 15 &&
          <Text style={[{
            color: getContrast(containerColor) === 'black' ? '#000000' : '#FFFFFF',
            fontSize: 12,
            fontFamily: 'helvetica-neue-bold',
            lineHeight: 25,
            letterSpacing: 0.58
          }, barTextStyle]}>
            {barText}
          </Text>
        } */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  barContainer: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    backgroundColor: '#FFCC02',
    width: '100%',
    height: '25%'
  }
})

export default ProgressBar;