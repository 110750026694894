import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

class ButtonGroup extends Component {


  render() {

    const { children } = this.props;

    return (
      <View style={styles.container}>
        {
          children.map((child, index) => {

            if (index !== children.length - 1) {
              return (
                  React.cloneElement(child, {
                    style: { ...child.props.style, borderRightWidth: 1, borderRightColor: '#EEEEEE' }
                  })
              );
            }
            
            return child;
          })
        }
      </View>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  }
})

export default ButtonGroup;