import React, { Component } from 'react';
import { ImageBackground, Image, Dimensions, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Avatar, Divider, Icon, ListItem} from 'react-native-elements';
import { withRouter } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';

import { parseQueryStringParams } from '../utilities/utilities';

import Input from '../components/Input';
import ScaledImage from '../components/ScaledImage';
import ActionBar from '../components/ActionBar';
import ProgressBar from '../components/ProgressBar';
import RankBadge from '../components/RankBadge';

import { getCampaign } from '../services/CampaignService';
import { getUser } from '../services/UserService';
import { getMembership } from '../services/MembershipService';

import { getContrast } from '../utilities/utilities';

const { height, width } = Dimensions.get('window');

class LandingScreen extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      quantity: 1,
      user: null,
      campaign: null
    }

    this._loadData = this._loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this._loadData();
  }

  async _loadData() {
    const query = this.props.location.search;
    let params;

    if (query) {
      params = parseQueryStringParams(query);
    }

    try {

      // if (!params?.userId || !params?.campaignId) {
      //   throw new Error("Missing Parameters: Unable to load data");
      // }

      // const userReq = getUser(params.userId);
      // const campaignReq = getCampaign(params.campaignId);
      const campaignReq = getCampaign(this.props.match.params.campaignId);
      // const membershipReq = getMembership(params.userId, params.campaignId);

      // const [user, campaign, membership] = await Promise.all([userReq, campaignReq, membershipReq]);
      const [campaign] = await Promise.all([campaignReq]);

      // this.setState({
      //   user: user.data,
      //   campaign: campaign.data,
      //   membership: membership.data,
      //   platform: params.platform
      // });
      this.setState({
        campaign: campaign.data,
      });

    } catch(error) {
      console.log("ERROR LOADING DATA IN LANDING", error);
    }
  }

  handleChange(evt, name) {
    this.setState({
      [name]: evt.nativeEvent.text
    });
  }

  handleSubmit() {
    this.props.history.push({
      pathname: '/checkout',
      state: {
        ...this.state
      }
    });
  }

  render() {
    const { campaign, quantity, user } = this.state;
    console.log("CAMPAIGN LOADED", campaign);
    return (
      <View style={{paddingHorizontal: width * 0.12, backgroundColor: 'gray'}}>
        <ScrollView
          style={[styles.container]}
          showsVerticalScrollIndicator={false}
        >
          <View style={{backgroundColor: '#086B3A', height: height * 0.01}}></View>
          <View style={{height: height*0.9, zIndex: 2}}>
            <ImageBackground
              source={{uri: 'https://media1.s-nbcnews.com/i/newscms/2020_30/3398393/200720-mater-dei-at-st-john-bosco-ew-351p_ae9fa84a450bee5c4f37e61861ec3ff3.jpg'}}
              style={{
                flex: 1,
                backgroundColor: '#000000'
              }}
              imageStyle={{opacity: 0.5}}
            >
              <>
                <View style={{height: '15%', backgroundColor: 'transparent', paddingHorizontal: '5%', flexDirection: 'row'}}>
                  <View style={{height: '100%', width:150}}>
                    <Image
                      source={{ uri: campaign?.organization_logo }}
                      style={{
                        width: undefined,
                        height: '100%',
                        backgroundColor: 'transparent'
                      }}
                      resizeMode="contain"
                    />
                  </View>
                  <View style={{flex: 1, justifyContent: 'center', paddingLeft: '2%'}}>
                      <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 24, lineHeight: 29, letterSpacing: 3.87, color: '#FFFFFF'}}>{campaign?.name}</Text>
                  </View>
                </View>
                <View style={{flex: 1}}>
                  <View style={{height: '33%', flexDirection: 'row', paddingHorizontal: '10%', marginTop: '10%'}}>
                      <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 100, lineHeight: 123, letterSpacing: 30, color: '#FFFFFF'}}>{campaign?.name}</Text>
                  </View>
                </View>
                <View style={{position: 'absolute', width: '100%', left: 0, top: 0, bottom: -1 * height * 0.23 * 0.3, alignItems: 'center'}}>
                  <ActionBar
                    containerStyle={{
                      position: 'absolute',
                      height: height * 0.23,
                      width: '90%',
                      boxShadow: '-10px 10px 5px rgba(88,88,88,0.1)'
                    }}
                    theme={{
                      primary: campaign?.theme.primary,
                      secondary: campaign?.theme.secondary
                    }}
                  />
                </View>
              </>
            </ImageBackground>
            
          </View>
          <View style={{background: 'repeating-linear-gradient(55deg, #E1E1E1, #E1E1E1 100px, #F0F0F0 100px, #F0F0F0 200px)', height: height * 0.2}}></View>
          <View style={{height: height * 1.2, paddingTop: '5%', paddingHorizontal: '3%', boxShadow: '0px -10px 5px rgba(88,88,88,0.1)', flexDirection: 'row'}}>
            <View style={{flex: 3}}>
              <View style={{flexDirection: 'row', paddingBottom: '5%'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 40, lineHeight: 48, letterSpacing: 1.92, color: '#333333'}}>THANK YOU </Text>
                <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 40, lineHeight: 48, letterSpacing: 1.92, color: '#333333', fontWeight: 'bold'}}>FOR YOUR SUPPORT</Text>
              </View>
              <View>
                {
                  campaign?.media?.videos?.campaign?.videoUrl &&
                  <Vimeo
                    video={campaign?.media?.videos?.campaign?.videoUrl}
                    width={undefined}
                    height={480}
                    style={{}}
                  />
                }
              </View>
              <View style={{paddingTop: '4%'}}>
                <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#333333'}}>
                {`Thank you for visiting our fundraising page.  We are very excited to start the season up again and look forward to the challenges ahead.

We have many goals set for ourselves and have been working tirelessly to achieve them this past year.  Some of our goals are individual ones everyone has been working on independently, others are team goals we work on all together, and lastly we have financial goals we need to meet in order to accomplish them all.  Running a successful program is expensive and we need your help.
                
Please donate as much as you can to help as it is much needed and greatly appreciated. With your help we can really accomplish everything we have set out to do this year.
                
Please remember to share, tweet, post, text, or email our campaign to others.`}
                </Text>
              </View>
            </View>
            <View style={{flex: 1, alignItems: 'flex-end', paddingTop: '14%'}}>
              <View style={{height: '30%', borderRadius: 4, width: '80%', position: 'absolute', right: 0, top: `${-1 * 12}%`, width: '90%', boxShadow: '0 0 3px 8px rgba(88,88,88,0.1)'}}>
                <View style={{flex: 1, backgroundColor: campaign?.theme.secondary, justifyContent: 'space-around', paddingHorizontal: '10%', paddingVertical: '3%'}}>
                  <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 22, lineHeight: 27, color: getContrast(campaign?.theme.secondary) === 'black' ? '#000000' : '#FFFFFF'}}>SHARE FUNDRAISER</Text>
                  <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 22, color: getContrast(campaign?.theme.secondary) === 'black' ? '#000000' : '#FFFFFF'}}>
                    Share this on social media and really make a difference
                  </Text>
                </View>
                <View style={{flex: 1, backgroundColor: '#FFFFFF', alignItems: 'center'}}>
                  <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                    <Icon
                      name='facebook-f'
                      type='font-awesome'
                      reverse
                      iconStyle={{fontSize: 40}}
                      underlayColor="blue"
                      color="#4468A3"
                      size={28}
                    />
                    <Icon
                      reverse
                      name='twitter'
                      type='font-awesome'
                      color='#f50'
                      color="#1DA1F2"
                      size={28}
                      iconStyle={{fontSize: 40}}
                    />
                  </View>
                  <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 18, lineHeight: 22, color: '#000000', fontWeight: 'bold'}}>Shares: 0</Text>
                </View>
              </View>
              <View style={{height: '60%', backgroundColor: '#F6F6F6', width: '90%', borderLeftWidth: 6, borderLeftColor: campaign?.theme.primary, borderRadius: 4, paddingHorizontal: '5%', justifyContent: 'space-evenly'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 22, lineHeight: 27, color: '#333333'}}>RECENT DONATIONS</Text>
                <View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>Jill Smith</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>$20</Text>
                  </View>
                  <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 25, color: '#333333'}}>Hope this helps with your softball fundraiser.  Have a great season.</Text>
                </View>
                <Divider />
                <View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>Jon Donath</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>$20</Text>
                  </View>
                  
                </View>
                <Divider />
                <View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>Michael Sampson</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>$20</Text>
                  </View>
                 
                </View>
                <Divider />
                <View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10}}>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>Jaime Brentlin</Text>
                    <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>$20</Text>
                  </View>
                  <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 25, color: '#333333'}}>Good luck this year!</Text>
                </View>
                <Divider />
                <View style={{alignItems: 'center', justifyContent: 'center'}}>
                  <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#000000'}}>View More</Text>
                </View>
              </View>
              <View style={{height: '40%', backgroundColor: '#F6F6F6', width: '90%', borderLeftWidth: 6, borderLeftColor: campaign?.theme.primary, borderRadius: 4, paddingHorizontal: '5%', justifyContent: 'space-evenly'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 22, lineHeight: 27, color: '#333333'}}>TOP FUNDRAISERS</Text>
                <ListItem
                  leftElement={() => <RankBadge rank={1}/>}
                  title={
                    <View style={{flexDirection: 'row', alignItems: 'center', paddingBottom: 5}}>
                      <Text style={{fontSize: 16, lineHeight: 22, letterSpacing: -0.38, color: '#333333', paddingBottom: 5}}>{"Jessica Marks"}</Text>
                    </View>
                  }
                  subtitle={'$636'}
                  titleStyle={{fontSize: 16, lineHeight: 22, letterSpacing: -0.38, color: '#333333', paddingBottom: 5}}
                  subtitleStyle={{fontSize: 13, lineHeight: 16, color: '#999999'}}
                />
                <Divider />
                <ListItem
                  leftElement={() => <RankBadge rank={2}/>}
                  title={
                    <View style={{flexDirection: 'row', alignItems: 'center', paddingBottom: 5}}>
                      <Text style={{fontSize: 16, lineHeight: 22, letterSpacing: -0.38, color: '#333333', paddingBottom: 5}}>{"Melissa Jacoby"}</Text>
                    </View>
                  }
                  subtitle={'$569'}
                  titleStyle={{fontSize: 16, lineHeight: 22, letterSpacing: -0.38, color: '#333333', paddingBottom: 5}}
                  subtitleStyle={{fontSize: 13, lineHeight: 16, color: '#999999'}}
                />
                <Divider />
                <ListItem
                  leftElement={() => <RankBadge rank={3}/>}
                  title={
                    <View style={{flexDirection: 'row', alignItems: 'center', paddingBottom: 5}}>
                      <Text style={{fontSize: 16, lineHeight: 22, letterSpacing: -0.38, color: '#333333', paddingBottom: 5}}>{"Angie Donnely"}</Text>
                    </View>
                  }
                  subtitle={'$402'}
                  titleStyle={{fontSize: 16, lineHeight: 22, letterSpacing: -0.38, color: '#333333', paddingBottom: 5}}
                  subtitleStyle={{fontSize: 13, lineHeight: 16, color: '#999999'}}
                />
                <Divider />
              </View>
            </View>

          </View>
          <View style={{height: height * 0.5}}>
            <ImageBackground
              source={{uri: 'https://media1.s-nbcnews.com/i/newscms/2020_30/3398393/200720-mater-dei-at-st-john-bosco-ew-351p_ae9fa84a450bee5c4f37e61861ec3ff3.jpg'}}
              style={{
                flex: 1,
                backgroundColor: '#000000'
              }}
              imageStyle={{opacity: 0.5}}
              blurRadius={7}
            >
              <View style={{flexDirection: 'row', paddingVertical: '4%', paddingHorizontal: '3%', flex: 1}}>
                <View style={{flex: 1}}>
                  <View style={{paddingBottom: 15}}>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 42, lineHeight: 60, color: '#FFFFFF', fontWeight: 'bold'}}>WHY </Text>
                      <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 42, lineHeight: 60, color: '#FFFFFF'}}>YOUR</Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 42, lineHeight: 60, color: '#FFFFFF'}}>SUPPORT </Text>
                      <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 42, lineHeight: 60, color: '#FFFFFF', fontWeight: 'bold'}}>MATTERS</Text>
                    </View>
                  </View>
                  <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 18, lineHeight: 27, color: '#FFFFFF', fontWeight: 'bold'}}>
                  {`We require a lot of our athletes both on and off the field. On top of daily sport specific training, we focus on school work, community stewardship, and personal development.

Cost associated with our efforts are substantially more than we receive. We rely on the support of friends, family, and the community in order to fulfill our goals and stay competitive.
                    `}
                  </Text>
                </View>
                <View style={{backgroundColor: '#FFFFFF', flex: 1, borderLeftWidth: 6, borderLeftColor: campaign?.theme?.primary, borderRadius: 4, paddingVertical: '2%', paddingHorizontal: '3%'}}>
                  <View style={{paddingBottom: 20}}>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 42, lineHeight: 60, color: '#333333', fontWeight: 'bold'}}>HOW </Text>
                      <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 42, lineHeight: 60, color: '#333333'}}>YOUR</Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{fontFamily: 'helvetica-neue-medium-italic', fontSize: 42, lineHeight: 60, color: '#333333'}}>DONATION </Text>
                      <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 42, lineHeight: 60, color: '#333333', fontWeight: 'bold'}}>IS USED</Text>
                    </View>
                  </View>
                  <Text style={{fontFamily: 'helvetica-regular', fontSize: 18, lineHeight: 27, color: '#333333'}}>
                    Your donation will be used to pay for uniforms, practice gear, travel expenses, tournament fees, and the team meals.  All of your donations will be used exclusively to further the success of our program.
                  </Text>
                </View>
              </View>
            </ImageBackground>
          </View>
          <View style={{height: height * 0.65, background: 'repeating-linear-gradient(55deg, #E1E1E1, #E1E1E1 100px, #F0F0F0 100px, #F0F0F0 200px)', alignItems: 'center'}}>
              <View style={{height: '33%', width: '50%', marginTop: '5%', textAlign: 'center', paddingVertical: '1%'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 18, lineHeight: 23, color: '#333333'}}>© Copyright 2021 American Youth Services</Text>
                <Text style={{fontFamily: 'helvetica-neue-regular', fontSize: 18, lineHeight: 22, color: '#333333', fontWeight: 'bold', marginTop: '3%'}}>6628 E Baseline Rd #102 · Mesa, AZ 85206 · 888-450-3634 · americanyouth.com</Text>
              </View>
          </View>
        </ScrollView>
        <ActionBar
          theme={{
            primary: campaign?.theme.primary,
            secondary: campaign?.theme.secondary
          }}
          containerStyle={{
            position: 'fixed',
            width: width - (width * 2 * 0.12)
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height,
    width: '100%',
    backgroundColor: '#FFFFFF'
  },
  cardContainer: {
    display: 'inline-block',
    width,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: width * 0.05,
    paddingVertical: width * 0.1
  },
  image: {
    alignSelf: 'flex-end'
  },
  contentContainer: {
    paddingHorizontal: width * 0.05,
    paddingVertical: width * 0.1
  },
  titleContainer: {

  },
  title: {
    fontFamily: 'helvetica-neue-bold',
    fontSize: 16,
    letterSpacing: 0.23,
    lineHeight: 19,
    color: '#000000',
    fontWeight: '600'
  },
  description: {
    fontSize: 15,
    lineHeight: 22,
    color: '#666666',
    fontFamily: 'helvetica-regular'
  },
  btnContainer: {
    backgroundColor: "#00A9FF",
    width: '40%'
  },
  btnStyle: {
    backgroundColor: "#00A9FF",
    paddingVertical: 15
  },
  btnLabel: {
    fontSize: 15,
    letterSpacing: -0.36,
    lineHeight: 22,
    fontFamily: 'helvetica-regular'
  }
})

export default withRouter(LandingScreen);
