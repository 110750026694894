import React from 'react';
import { StyleSheet, View } from 'react-native';

const Circle = (props) => {

  const {
    width,
    fill,
    border,
    borderWidth = 2
  } = props

  return (
    <View
      style={[
        styles.container,
        {
          width: width,
          borderRadius: width/2,
          height: width,
          backgroundColor: fill,
          borderColor: border,
          borderWidth: borderWidth
        }
      ]}
    >
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#EEEEEE',
    borderWidth: 2,
    borderColor: '#98EAA6'
  }
});

export default Circle;