import React, { Component } from 'react';
import { Dimensions, Image, StyleSheet, Text, View } from 'react-native';
import { Button, CheckBox, Divider } from 'react-native-elements';
import { withRouter } from 'react-router-dom';

import MediaQuery from 'react-responsive';

import { getCampaign } from '../services/CampaignService';
import { getUser } from '../services/UserService';

import { getContrast } from '../utilities/utilities';
import { transform } from 'lodash-es';


const { height, width } = Dimensions.get('window');

class SubscriptionsScreen extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      isSubmitting: false,
      isSuccessful: false,
      selectedIndex: 0
    }
    
    this._loadData = this._loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  async componentDidMount() {
    await this._loadData();
  }

  async _loadData() {
    console.log("PREFERENCES PARAMS", this.props.match.params)
    const campaignReq = getCampaign(this.props.match.params.campaignId);
    const userReq = getUser(this.props.match.params.memberId);

    try {
      const [ campaign, user ] = await Promise.all([ campaignReq, userReq ]);
      console.log("USER", user);
      this.setState({
        user: user.data,
        campaign: campaign.data,
      }, () => {
        document.title = `${this.state.user?.firstName} ${this.state.user?.lastName} | ${this.state.campaign?.name}`;
      });
    } catch(error) {
      console.log("ERROR", error);
    }

  }

  handleChange(index) {
    return () => {
      this.setState({
        selectedIndex: index
      })
    }
  }

  handleSubmit() {
    // Make API call
    this.setState({
      isSubmitting: true
    })

    setTimeout(() => {
      this.setState({
        isSubmitting: false,
        isSuccessful: true
      })
    }, 2000);
  }

  render() {
    const { campaign, isSubmitting, selectedIndex, user } = this.state;

    return (
      <>
        <MediaQuery maxDeviceWidth={480}>
          <View style={[styles.container, styles.containerSm]}>
            <View style={{width: '100%', height: height * 0.25}}>
              <View style={{position: 'absolute', top: 0, left: transform('50%'), width, height: '75%', zIndex: 2, alignItems: 'center', justifyContent: 'center'}}>
                <Image source={{uri: campaign?.organization_logo}} style={{width: 100, height: 100}}/>
              </View>
              <View style={{backgroundColor: campaign?.theme?.primary, height: '30%', width: '100%'}}></View>
              <View style={{backgroundColor: campaign?.theme?.primary, height: height * 0.25 * 0.8 , transform: 'skewY(-11deg)', width: '100%', position: 'absolute', top: 0, left: 0}}></View>
            </View>
            <View style={[styles.formContainer, styles.formContainerSm]}>
              <View style={{height: '30%', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-medium', fontSize: 20, marginBottom: 20}}>Manage Subscription Preferences</Text>
                <Text style={{width: '85%', fontFamily: 'helvetica-regular', lineHeight: 25, letterSpacing: 0.86}}>Select an option below to change your email subscription preferences</Text>
              </View>
              <Divider style={{ backgroundColor: "#808080", width: "100%"}} />
              <View style={styles.checkGroupContainer}>
                <CheckBox
                  title={`Unsubscribe from all emails from ${user?.firstName} ${user?.lastName}`}
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  checkedColor={campaign?.theme?.primary}
                  uncheckedColor={campaign?.theme?.primary}
                  checked={selectedIndex === 0}
                  onPress={this.handleChange(0)}
                  containerStyle={styles.checkboxContainer}
                  textStyle={[styles.checkBoxLabel, styles.checkBoxLabelSm]}
                />
                <CheckBox
                  title={`Unsubscribe from all emails from ${campaign?.name}`}
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  checkedColor={campaign?.theme?.primary}
                  uncheckedColor={campaign?.theme?.primary}
                  checked={selectedIndex === 1}
                  onPress={this.handleChange(1)}
                  containerStyle={styles.checkboxContainer}
                  textStyle={[styles.checkBoxLabel, styles.checkBoxLabelSm]}
                />
                <CheckBox
                  title="Unsubscribe from all emails"
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  checkedColor={campaign?.theme?.primary}
                  uncheckedColor={campaign?.theme?.primary}
                  checked={selectedIndex === 2}
                  onPress={this.handleChange(2)}
                  containerStyle={styles.checkboxContainer}
                  textStyle={[styles.checkBoxLabel, styles.checkBoxLabelSm]}
                />
                <CheckBox
                  title="I made a mistake. I don't want to unsubscribe"
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  checkedColor={campaign?.theme?.primary}
                  uncheckedColor={campaign?.theme?.primary}
                  checked={selectedIndex === 3}
                  onPress={this.handleChange(3)}
                  containerStyle={styles.checkboxContainer}
                  textStyle={[styles.checkBoxLabel, styles.checkBoxLabelSm]}
                />
              </View>
              <View style={{ height: '25%', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Button
                  title="Save Preferences"
                  buttonStyle={[styles.btn, { backgroundColor: campaign?.theme?.primary }]}
                  titleStyle={[styles.btnTitle, { color: getContrast(campaign?.theme.primary) === "#000000" ? "#000000" : '#FFFFFF' }]}
                  containerStyle={[styles.btnContainer, styles.btnContainerSm]}
                  loading={isSubmitting}
                  onPress={this.handleSubmit}
                />
              </View>
            </View>
          </View>
        </MediaQuery>
        <MediaQuery minDeviceWidth={481}>
          <View style={styles.container}>
            <View style={{width: '100%'}}>
              <View style={{backgroundColor: campaign?.theme?.primary, height: height * 0.25, width: '100%'}}></View>
              <View style={{backgroundColor: campaign?.theme?.primary, height: height * 0.15, transform: 'skewY(-11deg)', width: '100%', position: 'absolute', top: 0, left: 0, height: height * 0.5}}></View>
            </View>
            <View style={[styles.formContainer, styles.dropShadow]}>
              <View style={{height: '30%', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-medium', fontSize: 20, marginBottom: 20}}>Manage Subscription Preferences</Text>
                <Text style={{width: '85%', fontFamily: 'helvetica-regular', lineHeight: 25, letterSpacing: 0.86}}>Select an option below to change your email subscription preferences</Text>
              </View>
              <Divider style={{ backgroundColor: "#808080", width: "100%"}} />
              <View style={styles.checkGroupContainer}>
                <CheckBox
                  title={`Unsubscribe from all emails from ${user?.firstName} ${user?.lastName}`}
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  checkedColor={campaign?.theme?.primary}
                  uncheckedColor={campaign?.theme?.primary}
                  checked={selectedIndex === 0}
                  onPress={this.handleChange(0)}
                  containerStyle={styles.checkboxContainer}
                  textStyle={styles.checkBoxLabel}
                />
                <CheckBox
                  title={`Unsubscribe from all emails from ${campaign?.name}`}
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  checkedColor={campaign?.theme?.primary}
                  uncheckedColor={campaign?.theme?.primary}
                  checked={selectedIndex === 1}
                  onPress={this.handleChange(1)}
                  containerStyle={styles.checkboxContainer}
                  textStyle={styles.checkBoxLabel}
                />
                <CheckBox
                  title="Unsubscribe from all emails"
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  checkedColor={campaign?.theme?.primary}
                  uncheckedColor={campaign?.theme?.primary}
                  checked={selectedIndex === 2}
                  onPress={this.handleChange(2)}
                  containerStyle={styles.checkboxContainer}
                  textStyle={styles.checkBoxLabel}
                />
                <CheckBox
                  title="I made a mistake. I don't want to unsubscribe"
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  checkedColor={campaign?.theme?.primary}
                  uncheckedColor={campaign?.theme?.primary}
                  checked={selectedIndex === 3}
                  onPress={this.handleChange(3)}
                  containerStyle={styles.checkboxContainer}
                  textStyle={[styles.checkBoxLabel, styles.checkBoxLabelSm]}
                />
              </View>
              <View style={{ height: '25%', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Button
                  title="Save Preferences"
                  buttonStyle={[styles.btn, { backgroundColor: campaign?.theme?.primary }]}
                  titleStyle={[styles.btnTitle, { color: getContrast(campaign?.theme.primary) === "#000000" ? "#000000" : '#FFFFFF' }]}
                  containerStyle={styles.btnContainer}
                  loading={isSubmitting}
                  onPress={this.handleSubmit}
                />
              </View>
            </View>
          </View>
        </MediaQuery>
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    height: height
  },
  containerSm: {
    // justifyContent: 'center'
  },
  checkboxContainer: {
    backgroundColor: 'transparent',
    borderWidth: 0
  },
  checkGroupContainer: {
    backgroundColor: '#FFFFFF',
    height: '44%',
    justifyContent: 'center'
  },
  checkBoxLabel: {
    fontFamily: 'helvetica-regular'
  },
  checkBoxLabelSm: {
    lineHeight: 25
  },
  formContainer: {
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    height: height * 0.5,
    paddingHorizontal: 20,
  },
  formContainerSm: {
    height: height * 0.75
  },
  dropShadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    borderWidth: 1,
    borderColor: '#C3C3C3',
    borderRadius: 4
  },
  cancelMessageContainer: {
    flexDirection: 'row'
  },
  btn: {
    borderRadius: 4,
    paddingVertical: 15,
    alignItems: 'center'
  },
  btnTitle: {
    fontFamily: 'helvetica-neue-bold',
    fontSize: 18,
    lineHeight: 23,
    letterSpacing: 0.86
  },
  btnContainer: {
    width: '50%',
    justifyContent: 'center'
  },
  btnContainerSm: {
    width: '90%'
  }
})

export default withRouter(SubscriptionsScreen);
