import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import CheckoutScreen from './screens/CheckoutScreen';
import LandingScreen from './screens/LandingScreen';
import MemberScreen from './screens/MemberScreen';
import SubscriptionsScreen from './screens/SubscriptionsScreen';

export default function App() {
  return (
    <Router>
      <style type="text/css">{`
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
        }

        @font-face {
          font-family: 'FontAwesome';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
        }

        @font-face {
          font-family: 'AntDesign';
          src: url(${require('react-native-vector-icons/Fonts/AntDesign.ttf')}) format('truetype');
        }
      `}</style>

      <Switch>
        <Route exact path="/:campaignId" component={LandingScreen}/>
        <Route exact path="/:campaignId/members/:memberId" component={MemberScreen} />
        <Route exact path="/:campaignId/members/:memberId/subscriptions" component={SubscriptionsScreen} />
      </Switch>
    </Router>

  );
}
