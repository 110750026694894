import React, { Component } from 'react';
import { Dimensions, ImageBackground, View } from 'react-native';

const { height } = Dimensions.get('window');

class Carousel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0
    }
  }

  componentDidMount() {
    const numImages = this.props.images?.length || [];

    if (numImages > 0) {
      setInterval(() => {
        const { currentIndex } = this.state;
        let nextIndex = currentIndex + 1;
  
        if (currentIndex + 1 === numImages) {
          nextIndex = 0;
        }

        this.setState({
          currentIndex: nextIndex
        });
  
      }, 8000)
    }
  }

  componentDidUpdate(prevProps) {
    const numImages = this.props.images?.length || [];

    if (prevProps.images?.length) {
      console.log("EXITNG");
      return;
    }

    if (numImages > 0) {
      setInterval(() => {
        const { currentIndex } = this.state;
        let nextIndex = currentIndex + 1;
  
        if (currentIndex + 1 === numImages) {
          nextIndex = 0;
        }

        this.setState({
          currentIndex: nextIndex
        });
  
      }, 8000)
    }
  }

  render() {

    const {
      children,
      imageStyle = {},
      images = [],
      style = {}
    } = this.props;

    const { currentIndex } = this.state;

    return (
      <>
        {
          images.length === 0 &&
          <View style={{flex: 1, backgroundColor: '#C3C3C3'}}></View>
        }
        {
          images.length > 0 &&
          <ImageBackground
            source={{uri: images[currentIndex] }}
            style={[{
              flex: 1,
              backgroundColor: '#C3C3C3'
            }, style]}
            imageStyle={[{opacity: 0.5}, imageStyle]}
          >
            {children}
          </ImageBackground>
        }
      </>
    );
  }
}

export default Carousel;