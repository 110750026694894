import React, { Component } from 'react';
import { Dimensions, ScrollView, Text, View } from 'react-native';
import { Divider, Icon } from 'react-native-elements';
import Modal from 'modal-react-native-web';

import RankBadge from '../components/RankBadge';

import { getContrast } from '../utilities/utilities';

const { width, height } = Dimensions.get('window');

function compareTotals(a, b) {
  if (a.totalSales > b.totalSales) {
    return -1;
  }

  if (a.totalSales < b.totalSales) {
    return 1
  }

  return 0;
}

class TopFundraisersModal extends Component {

  render() {
    const { data, theme } = this.props;

    return (
      <View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.props.data.isVisible === undefined ? false : this.props.data.isVisible}
          onDismiss={() => {
            console.log("DISMISSING MODAL");
          }}
        >
          <View style={{height, flex: 1, paddingHorizontal: width * 0.3, backgroundColor: 'rgba(0,0,0,0.75)'}}>
            <View style={{height, backgroundColor: '#F6F6F6'}}>
              <View style={{height: '12%', flexDirection: 'row', alignItems: 'center', paddingHorizontal: '5%', justifyContent: 'space-between'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 22, lineHeight: 27, color: '#333333'}}>TOP FUNDRAISERS</Text>
                <Icon
                  type="antdesign"
                  name="close"
                  onPress={this.props.handleClose()}
                />
              </View>
              <Divider/>
              <ScrollView contentContainerStyle={{flexGrow: 1}}>
                <View style={{
                  height: '100%',
                  backgroundColor: '#F6F6F6',
                  width: '100%',
                  borderLeftWidth: 6,
                  borderLeftColor: theme.primary,
                  paddingHorizontal: '5%',
                  marginBottom: 20
                }}>
                  {
                    data.memberships.sort(compareTotals).map((membership, idx) => {
                      return (
                        <View>
                          <View style={{justifyContent: 'center', paddingVertical: 20}}>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10, alignItems: 'center'}}>
                              <RankBadge
                                fillColor={theme.primary}
                                strokeColor={theme.primary}
                                labelColor={getContrast(theme.primary) === 'black' ? '#000000' : '#FFFFFF'}
                                labelStyle={{ fontFamily: 'helvetica-neue-bold', fontSize: 25, lineHeight: 30, letterSpacing: 1.53 }}
                                rank={idx + 1}
                                width={50}
                              />
                              <View style={{ flex: 1, alignContent: 'flex-start', paddingHorizontal: 20}}>
                                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>{`${membership.userFirst} ${membership.userLast}`}</Text>
                                <Text style={{fontFamily: 'helvetica-neue-bold', fontSize: 20, lineHeight: 27, color: '#333333'}}>{`$${membership.totalSales}`}</Text>
                              </View>
                            </View>
                          </View>
                          <Divider />
                        </View>
                      )
                    })
                  }
                </View>
              </ScrollView>
            </View>
          </View>
        </Modal>
      </View>
    );
  }
}

export default TopFundraisersModal;