import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';

import Circle from './Circle';

const WIDTH = Dimensions.get('window').width;

const RankBadge = (props) => {

  const {
    fillColor = "transparent",
    strokeColor = "#000000",
    rank,
    width = WIDTH * 0.13,
    label,
    labelColor = '#FFFFFF',
    labelStyle = {},
    containerStyle = {}
  } = props;

  return (
    <View style={[styles.container, containerStyle]}>
      <Circle width={width} fill={fillColor} border={strokeColor} borderWidth={1} />
      <View style={styles.textContainer}>
        {
          label &&
          <Text style={styles.labelText}>{label}</Text>
        }
        <Text style={[styles.rankText, labelStyle, {color: labelColor}]}>{rank}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // flex: 0
  },
  textContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  labelText: {
    fontSize: 8,
    color: '#333333',
    letterSpacing: 0.05,
    lineHeight: 10
  },
  rankText: {
    fontSize: 17,
    color: '#333333',
    letterSpacing: -0.41,
    lineHeight: 22,
    fontWeight: 'bold'
  }
})

export default RankBadge;