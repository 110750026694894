import React, { Component, createRef } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-elements';

import ButtonGroup from './ButtonGroup';
import ProgressBar from './ProgressBar';

class ActionBar extends Component {

  constructor(props) {
    super(props);

    this.ref = createRef();
  }

  render() {

    const {
      barContainerStyle = {},
      barTextStyle = {},
      barText = "",
      data = {},
      fillTextStyle = {},
      fillText = "",
      barColor = "#FFFFFF",
      fillColor = "#000000",
      textContainerStyle = {},
      theme = {},
      handlePress
    } = this.props;
    console.log("HANDLE PRESS", this.props)

    return (
      <View style={[styles.container, this.props.containerStyle]}>
        <ProgressBar
          textContainerStyle={textContainerStyle}
          data={data}
          barContainerStyle={[barContainerStyle]}
          containerColor={theme?.secondary || '#FFFFFF'}
          barColor={theme?.primary || '#000000'}
          fillText={fillText}
          barText={barText}
          fillTextStyle={[{
            fontFamily: 'helvetica-neue-bold',
            fontSize: 12,
            lineHeight: 15,
            letterSpacing: 0.58,
            color: '#FFFFFF'
          }, fillTextStyle]}
          barTextStyle={[{
            fontFamily: 'helvetica-neue-bold',
            fontSize: 13,
            lineHeight: 16,
            letterSpacing: 0.62,
            color: '#000000'
          }, barTextStyle]}
        />
        {this.props.children}
        {/* <ButtonGroup>
          <Button
            style={{flex: 1}}
            containerStyle={{flex: 1, borderRadius: 0}}
            onPress={handlePress({donationAmount: 35})}
            ViewComponent={() => (
              <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: '#000000'}}>Donate</Text>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 61, fontSize: 50, letterSpacing: 2.4, color: this.props.theme?.primary || '#086B3A'}}>$35</Text>
              </View>
              )
            }
          />
          <Button
            style={{flex: 1}}
            containerStyle={{flex: 1, borderRadius: 0}}
            onPress={handlePress({donationAmount: 50})}
            ViewComponent={() => (
              <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: '#000000'}}>Donate</Text>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 61, fontSize: 50, letterSpacing: 2.4, color: this.props.theme?.primary || '#086B3A'}}>$50</Text>
              </View>
              )
            }
          />
          <Button
            style={{flex: 1}}
            containerStyle={{flex: 1, borderRadius: 0}}
            onPress={handlePress({donationAmount: 100})}
            ViewComponent={() => (
              <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: '#000000'}}>Donate</Text>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 61, fontSize: 50, letterSpacing: 2.4, color: this.props.theme?.primary || '#086B3A'}}>$100</Text>
              </View>
              )
            }
          />
          <Button
            style={{flex: 1}}
            containerStyle={{flex: 1, borderRadius: 0}}
            onPress={handlePress({donationAmount: 250})}
            ViewComponent={() => (
              <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: '#000000'}}>Donate</Text>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 61, fontSize: 50, letterSpacing: 2.4, color: this.props.theme?.primary || '#086B3A'}}>$250</Text>
              </View>
              )
            }
          />
          <Button
            style={{flex: 1}}
            containerStyle={{flex: 1, borderRadius: 0}}
            onPress={handlePress({donationAmount: 500})}
            ViewComponent={() => (
              <View style={{backgroundColor: '#FFFFFF', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 25, fontSize: 20, letterSpacing: 0.96, color: '#000000'}}>Donate</Text>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 61, fontSize: 50, letterSpacing: 2.4, color: this.props.theme?.primary || '#086B3A'}}>$500</Text>
              </View>
              )
            }
          />
          <Button
            style={{flex: 1}}
            containerStyle={{flex: 1, borderRadius: 0}}
            onPress={handlePress()}
            ViewComponent={() => (
              <View style={{backgroundColor: this.props.theme?.primary || '#086B3A', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 29, fontSize: 24, letterSpacing: 1.15, color: '#FFFFFF'}}>CUSTOM</Text>
                <Text style={{fontFamily: 'helvetica-neue-bold', lineHeight: 29, fontSize: 24, letterSpacing: 1.15, color: '#FFFFFF'}}>AMOUNT</Text>
              </View>
              )
            }
          />
        </ButtonGroup> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '25%',
    backgroundColor: '#FFFFFF',
    // position: 'fixed',
    bottom: 0
  }
})

export default ActionBar;