import React, { Component } from 'react';
import { Dimensions, ScrollView, StyleSheet, Text, View } from 'react-native';
import { ToastContainer, toast } from 'react-toastify';
import '../toast.css';
import 'react-toastify/dist/ReactToastify.css';

import CustomerDetailsForm from '../components/CustomerDetailsForm';
import FormWizard from '../components/FormWizard';
import Header from '../components/Header';
import OrderConfirmation from '../components/OrderConfirmation';
import OrderSuccess from '../components/OrderSuccess';
import PaymentDetailsForm from '../components/PaymentDetailsForm';

import * as Yup from 'yup';
import valid from 'card-validator';

import { parseQueryStringParams } from '../utilities/utilities';
import { charge, getSale } from '../services/SaleService';

import { STATES } from '../constants';

const { height } = Dimensions.get('window');

const validationSchemas = [
  Yup.object().shape({
    customer: Yup.object().shape({
      firstName: Yup.string()
        .required("First name is required"),
      lastName: Yup.string()
        .required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string()
        .matches(
          new RegExp(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/),
          "Invalid phone number"
        )
        .required("Phone number is required"),
      address: Yup.string()
        .required("Address is required"),
      city: Yup.string()
        .required("City is required"),
      zipCode: Yup.string()
        .required("Zip code is required")
    })
  }),
  Yup.object().shape({
    cardNumber: Yup.string()
      .test(
        'is-valid-card',
        'Invalid credit card number',
        value => valid.number(value).isValid
      )
      .required("Credit card number is required"),
    cvc: Yup.string()
      .test(
        'is-valid-cvc',
        'Invalid cvc code',
        value => valid.cvv(value).isValid
      )
      .required("Cvv code is required")
    }),
];

class CheckoutScreen extends Component {

  _defaultState = {
    user: "",
    paymentAmount: 0,
    unitsSold: 0,
    userLastName: "",
    platform: "",
    userFirstName: "",
    customer: {
      firstName: "",
      lastName: "",
      phone: "",
      coords: {},
      email: "",
      state: STATES[0].value
    },
    team: "",
    campaign: "",
    id: "",
    paymentType: "credit",
    cardNumber: "",
    cvc: "",
    expiry: "",
    didNotify: false
  }

  constructor(props) {
    super(props);

    this.state = this._defaultState;

    this._loadData = this._loadData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { search, state } = this.props.location;

    if (search) {
      const params = parseQueryStringParams(search);
      return await this._loadData(params);
    }

    if (this.props.location.state) {
      this.setState({
        unitsSold: Number(state.quantity),
        unitPrice: state.campaign.unitPrice,
        userFirstName: state.user.firstName,
        userLastName: state.user.lastName,
        campaign: state.campaign.id,
        user: state.user.id,
        paymentAmount: (Number(state.quantity) * state.campaign.unitPrice) + Number(state.quantity),
        team: state.membership.team,
        platform: state.platform
      });
    }

  }

  async _loadData(params) {
    try {

      if (params.saleId) {
        const { data } = await getSale(params.saleId);

        this.setState({
          ...data
        });
      }

    } catch(error) {
      console.log("ERROR LOADING DATA IN CHECKOUT", error);
    }
  }

  async handleSubmit(values) {
    const { didNotify, cardNumber, cvc, expiry, ...payload } = values;

    // Format credit card data for backend consumption
    payload.card = {
      number: cardNumber,
      cvc,
      exp_month: Number(expiry.slice(0, 2)),
      exp_year: Number(`20${expiry.slice(2)}`)
    };

    console.log("PAYMENT PAYLOAD", payload);

    try {
      const response = await charge(payload);
      console.log("RESPONSE FROM CHARGE", response);
    } catch(err) {
      console.log('ERROR CHARGING', err);
      toast.error(err.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw err;
    }

  }

  handleChange(cb, name, type, val) {
    return (evt) => {
      const { unitsSold, didNotify } = this.state;
      let value;

      switch(type) {
        case 'input':
          value = evt.nativeEvent.text;
          break;
        case 'dropdown':
          value = evt
          break;
        case 'increment':
          value = unitsSold + 1;
          break;
        case 'decrement':
          value = unitsSold === 0 ? 0 : unitsSold - 1;
          break;
        case 'button':
          value = val;
          break;
        case 'check':
          value = !didNotify
          break;
        default:
          value = '';
      }

      this.setState({ [name] : value }, ()=>{console.log("STATE SET IN FORM NAV", this.state); if(cb) cb(name)(value);});
    }
  }

  render() {
    return (
      <>
        <ScrollView style={{height}}>
          <Header
            title="American Youth Services Checkout"
            titleStyle={styles.title}
            titleContainer={styles.titleContainer}
          />
          <View style={{height: height * 0.85}}>
          {/* <View style={{flex: 1, backgroundColor: 'green'}}> */}
            <FormWizard
              initialValues={this.state}
              validationSchemas={validationSchemas}
              onSubmit={this.handleSubmit}
            >
              <FormWizard.Page>
                <CustomerDetailsForm/>
              </FormWizard.Page>
              <FormWizard.Page>
                <PaymentDetailsForm/>
              </FormWizard.Page>
              <FormWizard.Page>
                <OrderConfirmation/>
              </FormWizard.Page>
              <FormWizard.Page>
                <OrderSuccess/>
              </FormWizard.Page>
            </FormWizard>
          </View>
        </ScrollView>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontFamily: 'sf-pro-display-bold',
    fontSize: 28
  },
  titleContainer: {
    marginBottom: 10
  }
})

export default CheckoutScreen;