import { STAGE } from '../constants';

const DOMAIN = STAGE === 'dev' ? 'https://rj2y4j37f4.execute-api.us-east-2.amazonaws.com/dev' : 'https://7iqdmbnsp6.execute-api.us-east-2.amazonaws.com/prod';
const ADMIN_DOMAIN = STAGE === 'dev' ? 'https://6lavrscmr2.execute-api.us-west-1.amazonaws.com/dev' : 'https://1bqvo9fs4i.execute-api.us-west-1.amazonaws.com/prod';

export const getSale = async (saleId) => {
  try {
    const response = await fetch(`${DOMAIN}/sales/${saleId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const res = await response.json();

    if (response.ok) {
      return res;
    } else {
      throw new Error(res.error);
    }

  } catch (err) {
    throw err;
  }
}

export const fetchSales = async (options = {}) => {
  try {

    const queryParams = new URLSearchParams(options);

    const response = await fetch(`${DOMAIN}/sales?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return await response.json();

  } catch (err) {
    return err;
  }
}

export const charge = async (data) => {
  try {
    const response = await fetch(`${ADMIN_DOMAIN}/donations/charge`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    console.log("RESPONSE", response);
    const json = await response.json();

    if (response.ok) {
      return json;
    }

    throw new Error(json.raw.message);

  } catch(err) {
    return Promise.reject(err);
  }
}